<ion-header class="naked">
  <ion-toolbar>
    <ion-title>
      REQUEST SUPPORT
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button class="icon-only clickable" (click)="dismiss()">
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p>Request support or share any questions or issues you may have regarding the CLICS system or products.</p>
  <h2>TOPIC:<span class="special"> {{topic}}</span> <span class="small"><ion-button class="clics" size="small" (click)="changeCategory()">change</ion-button></span></h2>
  <ion-list inset id="feedback-form">
    <ion-item lines="none">
      <ion-label position="stacked">Subject</ion-label>
      <ion-input #subjectInput type="text" [value]="this.subject" (ionChange)="updateSubject($event)"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="stacked" class="dropped">Detailed Description</ion-label>
      <ion-textarea #descriptionInput [value]="this.description" (ionChange)="updateDescription($event)"></ion-textarea>
    </ion-item>
    <ion-item lines="none">
      <ion-button class="clics" (click)="sendFeedback()" [disabled]="!ready">Send Now</ion-button>
    </ion-item>
    <p class="small">* carrier messaging rates may apply</p>
  </ion-list>
</ion-content>
