<ion-header>
  <div *ngIf="showNonModalElement()">
    <navigation-bar></navigation-bar>
    <navigation-sidebar current_root="admin"></navigation-sidebar>
  </div>
  <div *ngIf="showModalElement()">
    <ion-toolbar class="modal">
      <ion-title>Client History Settings</ion-title>
      <ion-buttons slot="primary">
        <ion-button (click)="closeModal()" class="icon-only clickable">
          <ion-icon ion-icon name="close-circle-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <navigation-sidebar current_root="admin"></navigation-sidebar>
  </div>
</ion-header>
<ion-content class="ion-padding app_settings">
  <h2 *ngIf="requestedView != 'modal' && !returnTo">Client History Settings</h2>
  <ion-grid>
    <ion-row *ngFor="let setting of settings">
      <ion-col>
        <h3 *ngIf="isNewCategory(setting.category)">{{setting.category}}</h3>
        <ion-item class="clics">
          <ion-label>{{setting.title}}</ion-label>
          <ion-toggle [checked]="!!settingsCtrl.settings[setting.key]" [value]="setting.key" (ionChange)="saveSettings($event)"></ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="showNonModalElement()">
  <page-footer [pageRoute]="'admin'" >&nbsp;</page-footer>
</ion-footer>
