/*
    Defines a CLICS Color Formula Request (any formula, really). The actual formula is kept in a string format. Individual
    component handling will come later. This is a dual-purpose object. It may describe a Color Formula in a library
    or a Formula Request, whose parameters instruct the CLICS System how to dispense a formula.

 */
import {IonDatetime} from '@ionic/angular';
import {CLiCSFormula} from "./formula";
import {CLiCSColorFormula} from './color-formula';

// import {createElementCssSelector} from "@angular/compiler";

export class CLiCSFormulaRequest extends CLiCSFormula {
  // Dispense Mode constants
  static readonly statusNotStarted = 0;
  static readonly statusMoreRequested = 1;
  static readonly statusDispensing = 10;
  static readonly statusDispensingFallback = 11;
  static readonly statusSuccess = 20;
  static readonly statusShortAttemptingFallback = 21;
  static readonly statusFailedGeneral = 30;
  static readonly statusFailedShort = 31;
  static readonly statusFailedFormula = 32;
  static readonly statusFailedTampering = 33;
  static readonly statusFailure = 39;
  static readonly statusCancelled = 40;

  dispensed: number = null;  // amount_dispensed
  bowl_color: string = null;
  applicationTimerLabel: string = 'Apply';
  dispensed_at: Date = null;
  staged: boolean = false;  // If true, the formula has been selected on a machine and should be locked
  stage_expires: number = null;  // timestamp after which the formula staging should not lock out the formula
  is_swatch: boolean = false;

  dispense_more_parent: string = null;  // Token of parent FR object for which this is a dispense more request

  // application timers
  applied_at: Date = null;        // Time when started applying to hair
  processed_at: Date = null;      // Time when finished applying to hair
  rinsed_at: Date = null;         // Time when rinsed from hair
  dried_at: Date = null;          // Time when starting to dry the hair
  finished_at: Date = null;       // Time when it's all finished (common to all FRs in the CS)
  dispense_status: number = CLiCSFormulaRequest.statusNotStarted;    // dispense status

  timerRollbackMs: number = 5000; // How long to rollback rather than to toggle
  appSummary: string = '';

  disp_pct: number = null;
  disp_g: number = null;
  pedigree: string = null; // string indicating source/status of this FR: C:CLICS, V:Conversion, P:My Colors, M:Modified

  // Item can be constructed with either a data object or a string
  constructor(data: any = null, regenerate_token: boolean = false) {
    super(data);

    if (typeof (data) == 'string') {
      data = JSON.parse(data);
    }
    this.loadObj(data, true);

    if (data instanceof CLiCSColorFormula) {
      this.loadFormula(data);
    }

    this.assertToken(regenerate_token);
    this.assertLocalIdent();
    this.setTimerLabel();
    this.updateInfo();
    this._updateAPPSummary();
  }

  // Load parameters local to this class. Setting localOnly prevents calling the super loadObj class, so only
  // parameters specific to this sub-class are loaded.
  loadObj(data: any, localOnly: boolean = false) {
    if (data !== undefined) {
      if (localOnly == false)
        super.loadObj(data);

      if (data.params) {
        this.params.length = 0;
        for (let param of data.params) {
          this.setParam(param.type, param.value, param.mod);
        }
      } else
        this.params.length;

      if ('bowl_color' in data)
        this.bowl_color = data.bowl_color;
      if ('dispensed' in data)
        this.dispensed = data.dispensed;
      if ('staged' in data)
        this.staged = data.staged;
      if ('stage_expires' in data)
        this.stage_expires = data.stage_expires;
      this.setTimestamps(data);
      if ('dispense_status' in data)
        this.dispense_status = data.dispense_status;
      if ('dispense_more_parent' in data)
        this.dispense_more_parent = data.dispense_more_parent;
      if ('is_swatch' in data)
        this.is_swatch = (data.is_swatch == true)
      if ('product_line' in data)
        this.product_line = data.product_line
      if ('pedigree' in data) {
        if (data.pedigree == '') {
          this.pedigree = null;
        } else {
          this.pedigree = data.pedigree;
        }
      }
    }
  }

  // load this object from a CLiCSColorFormula object
  loadFormula(formula: CLiCSColorFormula) {
    // this.color_formula_id = formula.token;
    this.rgb = formula.rgb;
    this.thumb = formula.thumb;
    this.title = formula.title;
    this.mode = formula.mode;
    this.formula_text = formula.formula_text;
    this.product_line = formula.product_line;
    this.params.length = 0;
    for (let param of formula.params) {
      this.setParam(param.type, param.value, param.mod); // Add or update param
    }
    this.assertToken(true); // Never use the Color Formula token in the Formula Request
  }

  // Takes the fields from a SessionEvent formula hash and updates this object
  updateFromEvent(eventData: any) {
    if ('ordinal' in eventData)
      this.ordinal = eventData.ordinal;
    if ('bowl_color' in eventData)
      this.bowl_color = eventData.bowl_color;
    if ('dispense_status' in eventData)
      this.dispense_status = eventData.dispense_status;
    if ('staged' in eventData)
      this.staged = eventData.staged == true;
    if ('stage_expires' in eventData)
      this.stage_expires = eventData.stage_expires;
    if ('weight' in eventData)
      this.amount = parseFloat(eventData.weight);
    if ('disp_pct' in eventData)
      this.disp_pct = eventData.disp_pct;
    if ('disp_g' in eventData)
      this.disp_g = eventData.disp_g;
    this.setTimestamps(eventData);
  }

  // Generate a random 32 character hex string
  static getRanHex = size => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
  }

  // For new Formula Requests, assign the UUID token in the mobile app rather than on the server.
  assertToken(force: boolean = false) {
    if (!this.token || force) {
      this.token = CLiCSFormulaRequest.getRanHex(30);
    }
  }

  // Sets timer timestamps from data object
  setTimestamps(data: any) {
    if ('dispensed_at' in data) {
      if (data.dispensed_at)
        this.dispensed_at = new Date(data.dispensed_at);
      else
        this.dispensed_at = null;
    }
    if ('applied_at' in data) {
      if (data.applied_at)
        this.applied_at = new Date(data.applied_at);
      else
        this.applied_at = null;
    }
    if ('processed_at' in data) {
      if (data.processed_at)
        this.processed_at = new Date(data.processed_at);
      else
        this.processed_at = null;
    }
    if ('rinsed_at' in data) {
      if (data.rinsed_at)
        this.rinsed_at = new Date(data.rinsed_at);
      else
        this.rinsed_at = null;
    }
    if ('dried_at' in data) {
      if (data.dried_at)
        this.dried_at = new Date(data.dried_at);
      else
        this.dried_at = null;
    }
    if ('finished_at' in data) {
      if (data.finished_at)
        this.finished_at = new Date(data.finished_at);
      else
        this.finished_at = null;
    }
  }

  // Returns true if fully dispensed or dispensing has failed
  isDispensed(): boolean {
    return (this.dispense_status >= CLiCSFormulaRequest.statusSuccess);
  }

  // Returns true if fully dispensed or dispensing has failed
  notStarted(): boolean {
    return (this.dispense_status < CLiCSFormulaRequest.statusDispensing && !this.isStaged());
  }

  // Returns true of the FR was staged and that staging hasn't expired.
  isStaged(): boolean {
    return (!!this.stage_expires && this.stage_expires > (Date.now() / 1000));
  }

  // Sets the timer label for this FR. Used to indicate the state of the timer
  // Get application timer does this same thing.
  // TODO: needed? delete this method?
  setTimerLabel() {
    if (this.finished_at) {
      this.applicationTimerLabel = 'Finished';
    } else {
      if (this.dried_at) {
        this.applicationTimerLabel = 'Drying';
      } else {
        if (this.rinsed_at) {
          this.applicationTimerLabel = 'Rinsing';
        } else {
          if (this.processed_at) {
            this.applicationTimerLabel = 'Processing';
          } else {
            if (this.applied_at) {
              this.applicationTimerLabel = 'Applying';
            } else
              this.applicationTimerLabel = 'Apply'
          }
        }
      }
    }
  }

  // Sets the token of a parent FR object for which this FR is a Dispense More request
  setDispenseMoreParent(formula: CLiCSFormulaRequest = null) {
    this.dispense_more_parent = formula ? formula.token : null;
  }

  // Returns the latest timestamp (Date) along with a mode string
  getApplicationTimer(): any {
    let latestTimer: Date = null;
    let modeText: string = 'ready';
    if (this.finished_at) {
      modeText = 'finished';
      this.applicationTimerLabel = '';
    } else {
      if (this.dried_at) {
        latestTimer = this.dried_at;
        modeText = 'drying';
        this.applicationTimerLabel = '';
      } else {
        if (this.rinsed_at) {
          latestTimer = this.rinsed_at;
          modeText = 'rinsing';
          this.applicationTimerLabel = '';
        } else {
          if (this.processed_at) {
            latestTimer = this.processed_at;
            modeText = 'processing';
            this.applicationTimerLabel = 'Processing';
          } else {
            if (this.applied_at) {
              latestTimer = this.applied_at;
              modeText = 'applying';
              this.applicationTimerLabel = 'Applying';
            } else
              this.applicationTimerLabel = 'Apply';
          }
        }
      }
    }

    // Calculate timer display string
    let timerStr: string = '';
    if (latestTimer != null && ['ready', 'applying', 'processing'].indexOf(modeText) >= 0) {
      const diffSecs = (Date.now() - latestTimer.getTime()) / 1000;
      const secs = Math.floor(diffSecs % 60);
      timerStr = secs < 10 ? `${Math.floor(diffSecs / 60)}:0${secs}` : `${Math.floor(diffSecs / 60)}:${secs}`;
    }

    return {
      started: this.applied_at != null,
      finished: this.finished_at != null,
      mode: modeText,
      label: this.applicationTimerLabel,
      timer: latestTimer,
      timerStr: timerStr
    }
  }

  // Sets the next timer in series and updates the label and such
  advanceTimer(forceAdvance: boolean = false): any {
    const activeTimerInfo: any = this.getApplicationTimer();
    const doAdvance =
      activeTimerInfo.timer == null ||
      (Date.now() - activeTimerInfo.timer.getTime()) >= this.timerRollbackMs;

    if (doAdvance || forceAdvance) {
      if (this.finished_at == null && ['ready', 'applying'].indexOf(activeTimerInfo.mode) >= 0) {
        if (this.dried_at && this.finished_at) {
          this.finished_at = new Date();
        } else {
          if (this.rinsed_at) {
            this.dried_at = new Date();
          } else {
            if (this.processed_at) {
              this.rinsed_at = new Date();
            } else {
              if (this.applied_at)
                this.processed_at = new Date();
              else
                this.applied_at = new Date();
            }
          }
        }
      }
      this._updateAPPSummary();
      return this.getApplicationTimer();
    } else {
      return this.rollbackTimer();
    }
  }

  // Roll back timers in sequence. Used to undo an advanceTimer() call. Up to caller to prevent multiple calls here.
  rollbackTimer(): any {
    if (this.finished_at)
      this.finished_at = null;
    else {
      if (this.dried_at)
        this.dried_at = null;
      else {
        if (this.rinsed_at)
          this.rinsed_at = null;
        else {
          if (this.processed_at)
            this.processed_at = null;
          else if (this.applied_at)
            this.applied_at = null;
        }
      }
    }
    this._updateAPPSummary();
    return this.getApplicationTimer();
  }

  // Sets the rinse time regardless of the state of the other timers
  setRinseTimer() {
    const doToggleRinse = this.rinsed_at == null || (Date.now() - this.rinsed_at.getTime()) < this.timerRollbackMs;
    if (doToggleRinse) {
      if (this.rinsed_at == null)
        this.rinsed_at = new Date();
      else
        this.rinsed_at = null;
    }
    this._updateAPPSummary();
    return (this.rinsed_at != null);
  }

  // Clear all APPLICATION timers, titles, amount dispensed, bowl color. Useful when duplicating a FR
  resetStatus() {
    this.applied_at = null;
    this.processed_at = null;
    this.rinsed_at = null;
    this.dried_at = null;
    this.finished_at = null;
    this.appSummary = '';
    this.applicationTimerLabel = 'Apply';
    this.dispensed = null;
    this.dispensed_at = null;
    this.bowl_color = null;

    this.dispense_status = CLiCSFormulaRequest.statusNotStarted;
    return this;
  }

  // Unlinks this instance from a parent instance. Useful if this object is copied from another FR
  unlink(): CLiCSFormula {
    this.token = null;
    this.resetStatus();
    return this;
  }

  // builds an APP summary string for display when rinsing has commenced
  _updateAPPSummary() {
    let summaryStr: string = "";
    let endTime: Date = null;
    if (!!this.applied_at) {
      if (this.processingFinished()) {
        endTime = this._serviceEndTime();
        if (this.processed_at) {
          const app_time = this._formatTime((this.processed_at.getTime() - this.applied_at.getTime()) / 1000);
          const proc_time = this._formatTime((endTime.getTime() - this.processed_at.getTime()) / 1000);
          summaryStr = `Apply: ${app_time}, Proc: ${proc_time}`;
        } else {
          const app_time = this._formatTime((endTime.getTime() - this.applied_at.getTime()) / 1000);
          summaryStr = `Apply & Process: ${app_time}`;
        }
      } else {
        if (!!this.processed_at) {
          const app_time = this._formatTime((this.processed_at.getTime() - this.applied_at.getTime()) / 1000);
          summaryStr = `Apply: ${app_time}`;
        }
      }
    }
    this.appSummary = summaryStr;
  }

  // Derives processing end time from the last processing action
  _serviceEndTime() {
    let endTime: Date = null;
    if (this.rinsed_at)
      endTime = this.rinsed_at;
    else {
      if (this.dried_at)
        endTime = this.dried_at;
      else
        endTime = this.finished_at;
    }
    return endTime;
  }

  // Formats total seconds as hours:minutes:seconds
  _formatTime(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds -= 3600 * hours;
    const minutes = Math.floor(totalSeconds / 60);
    totalSeconds -= 60 * minutes;
    const seconds = Math.floor(totalSeconds);

    const minStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

    if (hours > 0) {
      return `${hours}:${minStr}:${secStr}`;
    } else {
      return `${minutes}:${secStr}`;
    }
  }

  isRinsing(): boolean {
    return (this.rinsed_at != null && this.finished_at == null);
  }

  isProcessing() {
    return (this.applied_at != null || this.processed_at != null) && this.rinsed_at == null && this.finished_at == null;
  }

  processingFinished() {
    return (this.dispense_status >= CLiCSFormulaRequest.statusSuccess && (this.rinsed_at != null || this.finished_at != null || this.dried_at != null));
  }

  dispensedAmount() {
    if (this.dispensed)
      return (Math.round(this.dispensed));
    else
      return 0;
  }

  // Returns true if this is an additive-only formula
  isAdditive(additiveTypes:Array<String> = ['CoB', 'Li', 'CL']): boolean {
    let additive = true;
    let found = false;
    for (const param of this.params) {
      if (param.type == 'compName' || param.type == 'compOrig') {
        found = true;
        if (!additiveTypes.includes(param.value) && param.value.match(/D\d+/) == null) {
          additive = false;
        }
      }
    }
    return additive && found;
  }

  // Returns the first additive parameter matching the additive name ('CoB', 'Li', 'CL')
  additiveParam(additiveType: string): any {
    let result: any = null;
    if (!['CoB', 'Li', 'CL'].includes(additiveType)) {
      return null;
    }
    for (const param of this.params) {
      if (param.type == 'compName' || param.type == 'compOrig') {
        if (additiveType == param.value) {
          result = param;
          break;
        }
      }
    }
    return(result);
  }

  isLightener(): boolean {
    return this.isAdditive(['Li'])
  }

  isCoBonder(): boolean {
    return this.isAdditive(['CoB'])
  }

  isColorLock(): boolean {
    return this.isAdditive(['CL'])
  }

  // Include (or remove) developer indication from local title
  includeDevInTitle() {
    let match: any = null;
    // Remove all trailing developer matches:
    // match = /\s\+\sD\d+$/.exec(this.title);
    if (!!this.title && this.title.length > 0) {
      match = this.title.search(/\s\+\sD\d+$/);
      if (match > 0) {
        this.title = this.title.substr(0, match + 1);
      }
      if (this.hasDeveloper()) {
        const strength = this.developerStrength();
        this.title += ` + D${strength}`;
      }
    }
  }

  // THis is used with clics-icon com
  pedigreeText(): string {
    let result = '';
    if (!this.pedigree) {
      return result;
    }

    switch (this.pedigree.toLowerCase()) {
      case 'c': {  // CLICS curated color
        result = 'CLICS';
        break;
      }
      case 'p': {  // From personal collection, My Color
        result = 'MY COLORS';
        break;
      }
      case 'v': {  // Conversion, competitor comparison
        result = 'CONVERSIONS';
        break;
      }
      case 'm': {
        result = 'MODIFIED';
        break;
      }
      default: {
        break;
      }
    }
    return result;
  }

  // If the pedigree character is lower case it indicates that a "soft change" (CoB, pH, developer changes) to the
  // formula
  pedigreeSoftChange(): boolean {
    if (!this.pedigree) {
      return false;
    }
    if (this.pedigree == 'm' || this.pedigree == 'M') {  // already "hard-modified"
      return false;
    }

    return this.pedigree != this.pedigree.toUpperCase();
  }

  // Return pedigree flag for Lab Colors page target
  // NEW:if a "soft" modification happened we save the pedigree with lower case, else upper case
  static targetToPedigree(target: string, soft_modified: boolean = false): string {
    let pedigree: string = null;
    switch (target) {
      case 'clics': {
        pedigree = soft_modified ? 'c' : 'C';
        break;
      }
      case 'colors': {
        pedigree = soft_modified ? 'p' : 'P';
        break;
      }
      case 'conversions': {
        pedigree = soft_modified ? 'v' : 'V';
        break;
      }
      default: {
        break;
      }
    }
    return pedigree;
  }

  // Copies timer values from another FR into this one
  setTimersFromRemote(remote_formula: CLiCSFormulaRequest) {
    this.finished_at = remote_formula.finished_at;
    this.dried_at = remote_formula.dried_at;
    this.rinsed_at = remote_formula.rinsed_at;
    this.processed_at = remote_formula.processed_at;
    this.applied_at = remote_formula.applied_at;
  }

  // For identifying the class
  isFormulaRequest(): boolean {
    return true;
  }

  className(): string {
    return 'CLiCSFormulaRequest';
  }

}
