<div class="record-menu-container">
  <div class="record_menu">
    <!-- used on client list page -->
    <div class="alt-content" *ngIf="!!showAltContent">
      <div class="client-tabs narrow" ><span [class.active]='showAll == false' (click)="doAltAction('recent')" id="recent" title="recent" >Recent</span><span [class.active]='showAll' (click)="doAltAction('all')" id="all" title="all" >All</span></div>
      <div class="client-tabs wide" ><span [class.active]='showAll == false' (click)="doAltAction('recent')" title="recent" >Recent Clients</span><span [class.active]='showAll' (click)="doAltAction('all')" title="all" >All Clients</span></div>
    </div>
    <div class="alt-content" *ngIf="showAltContent == false">&nbsp;</div>
    <ion-icon name="ellipsis-horizontal" id="setting_icon" (click)="doOpenSettings()"></ion-icon>
    <ion-icon name="apps-outline" id="grid_icon" (click)="doViewGrid()"></ion-icon>
    <ion-icon *ngIf="showFilter" name="funnel-outline" id="filter_icon" (click)="doOpenFilter()"></ion-icon>
    <ion-icon name="search-outline" id="search_icon" (click)="doOpenSearch()"></ion-icon>
    <ion-icon name="add" id="add_icon" (click)="doAddItem()"></ion-icon>
    <ion-icon name="create-outline" id="edit_icon" (click)="doEditItem()"></ion-icon>
  </div>
</div>
