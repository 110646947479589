<!--
  A static version of the (Client) ApplicationPage. Used for display and use choices, does not offer "app" editing
  features.
-->
<ion-header class="naked">
  <ion-toolbar>
    <ion-title>App Details</ion-title>
    <ion-buttons slot="primary">
      <clics-icon text="CLOSE" class="clickable" (click)="closeModal()"></clics-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class='ion-padding'>
  <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div class="color_session_info" *ngIf="isColorSession">
    <div>
      <ion-grid class="table-view">
        <ion-row>
          <ion-col>
            <div class="key">Applied:</div>
            <div class="value" *ngIf="cs.finished_at">{{cs.finished_at.toLocaleDateString("en-US")}}</div>
          </ion-col>
        </ion-row>
        <ion-row no-lines no-border *ngIf="cs">
          <ion-col>
            <div class="key">Starting Level:</div>
            <div class="value">{{startingLevel}}</div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="session_photos" *ngIf="!!permissions.use_photos && showHeadshots()">
      <div class="photo before_photo" (click)="zoomPhoto(beforePhotoToken)">
        <p>Before</p>
        <img src="{{beforePhotoUrl}}" alt="Client before photo" />
      </div>
      <div class="photo after_photo" (click)="zoomPhoto(afterPhotoToken)">
        <p>After</p>
        <img src="{{afterPhotoUrl}}" alt="Client after photo" />
      </div>
      {{sessionPhotos.all.length}}
    </div>
  </div>
  <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
  <div class="client_application">
    <ion-grid class="table-view">
      <ion-row no-lines no-border *ngIf="cs && cs.title">
        <ion-col>
          <div class="key">Descriptive Name:</div>
          <div class="value">{{cs.title}}</div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-list>
      <ion-item-sliding *ngFor="let formula of formulas">
        <ion-item>
          <app-formula [formula]="formula" [showDevRatio]="true" [showPedigree]="!!permissions.show_pedigree" [useDispensedAmount]="true" (openColorDetail)="openColorDetailModal(formula, $event)"></app-formula>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </div>
  <div class="session-note" *ngIf="!!this.cs && this.clientNotes.length > 0">
    <h3>Notes</h3>
    <div class="client-note" *ngFor="let note of this.clientNotes" [attr.data-token]="note.token">
      <h4 (click)="editNote(note.token)">
        {{formattedDate(note.noteDate)}}
        <span>
          <ion-icon name="create"></ion-icon>
        </span>
      </h4>
      <p>{{note.noteText}}</p>
    </div>
  </div>
  <div class="other_photos" *ngIf="this.sessionPhotos.all.length > 0 && !!permissions.use_photos">
    <h3>Other Photos</h3>
    <photo-grid #photoGrid *ngIf="!!cs" (photoClick)="zoomPhoto($event)"></photo-grid>
  </div>
</ion-content>
<ion-footer>
  <div class="color_actions bottom_action">
    <ion-grid>
<!--      <ion-row *ngIf="isColorSession">-->
<!--        <ion-col class="clickable" [class.disabled]="!allowCamera">-->
<!--          <clics-icon [theme]="'dark'" [class.disabled]="!allowCamera" (click)="promptForPhotoDestination()" [text]="'Add Photo'"></clics-icon>-->
<!--        </ion-col>-->
<!--        <ion-col class="link_div">-->
<!--          <clics-icon [theme]="'dark'" (click)="promptForReplace()" [text]="'Use'"></clics-icon>-->
<!--        </ion-col>-->
<!--        <ion-col>-->
<!--          <clics-icon [theme]="'dark'" (click)="showNotes()" [text]="'Notes'"></clics-icon>-->
<!--        </ion-col>-->
<!--      </ion-row>-->
<!--      <ion-row *ngIf="! isColorSession">-->
<!--        <ion-col class="link_div">-->
<!--          <clics-icon [theme]="'dark'" (click)="promptForRemoval()" [text]="'Remove'"></clics-icon>-->
<!--        </ion-col>-->
<!--        <ion-col class="link_div">-->
<!--          <clics-icon [theme]="'dark'" (click)="promptForReplace()" [text]="'Use'"></clics-icon>-->
<!--        </ion-col>-->
<!--        <ion-col class="link_div">-->
<!--          <clics-icon [theme]="'dark'" (click)="closeModal()" [text]="'Cancel'"></clics-icon>-->
<!--        </ion-col>-->
<!--      </ion-row>-->


      <div class="color_actions bottom_action">
        <ion-grid>
          <ion-row lines="none">
            <ion-col class="link_div">
             <clics-icon [theme]="theme" class="disabled" [text]="'Create'"></clics-icon>
            </ion-col>
            <ion-col class="link_div">
             <clics-icon [theme]="theme" (click)="promptForReplace()" [text]="'Use'"></clics-icon>
            </ion-col>
            <ion-col class="link_div">
             <clics-icon [theme]="theme" (click)="showNotes()" [text]="'Notes'"></clics-icon>
            </ion-col>
          </ion-row>
          <ion-row lines="none">
            <ion-col class="link_div">
             <clics-icon [theme]="'dark'" class="disabled" *ngIf="!!permissions.access_hair" [text]="'Hair'"></clics-icon>
            </ion-col>
            <ion-col class="link_div">
             <clics-icon [theme]="'dark'" [class.disabled]="!allowCamera" (click)="promptForPhotoDestination()" [text]="'Photo'"></clics-icon>
            </ion-col>
            <ion-col class="link_div">
             <clics-icon [theme]="'dark'" class="disabled" *ngIf="!!permissions.access_consultation" [text]="'Consult'"
              ></clics-icon>
            </ion-col>
            <ion-col class="link_div">
             <clics-icon [theme]="'dark'"  (click)="closeModal()" [text]="'Cancel'"></clics-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-grid>
  </div>
<!--  <page-footer [isModal]="true" (closeModalNotify)="closeModal()">&nbsp;</page-footer>-->
</ion-footer>
