<ion-header>
</ion-header>
<ion-content class="ion-padding welcome-slides">
  <swiper #swiper [config]="config" class="welcome-slides">
    <ng-template swiperSlide>
        <img src="assets/images/welcome-1.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-2.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-3.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-4.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-5.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-6.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-7.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-8.png" />
    </ng-template>
    <ng-template swiperSlide>
        <img src="assets/images/welcome-9.png" />
    </ng-template>
  </swiper>
</ion-content>
<ion-footer class="welcome-slides">
  <ion-button class="clics" (click)="dismiss()">Close</ion-button>
</ion-footer>
