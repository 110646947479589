<!-- Generated template for the NavigationSidebarComponent component -->
<div class="navigation-sidebar-container">
  <div class="nav">
    <div class="page-links" id="links4dispenser" *ngIf="current_root == 'dispenser'">
      <ul>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='queue'" (click)="doPageNavigation('queue')" [text]="'QUEUE'"></clics-icon></li>
        <li *ngIf="permissions.quick_dispense"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='quick'" (click)="doPageNavigation('quick')" [text]="'Quick'"></clics-icon></li>
        <li *ngIf="permissions.dispense_history"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='dispensed'" (click)="doPageNavigation('dispensed')" [text]="'Dispensed'"></clics-icon></li>
      </ul>
    </div>
    <div class="page-links" id="links4lab" *ngIf="current_root == 'lab'">
      <ul>
        <li *ngIf="permissions.design_clics"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_clics'" (click)="doPageNavigation('lab_clics')" [text]="'CLICS<sup>&reg;</sup>'"></clics-icon></li>
        <li *ngIf="permissions.design_topchic"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_topchic'" (click)="doPageNavigation('lab_topchic')" [text]="'TopChic'"></clics-icon></li>
        <li *ngIf="permissions.design_colorance"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_colorance'" (click)="doPageNavigation('lab_colorance')" [text]="'Colorance'"></clics-icon></li>
        <li *ngIf="permissions.design_conversions"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_convert'" (click)="doPageNavigation('lab_convert')" [text]="'Conversions'"></clics-icon></li>
        <li *ngIf="permissions.design_my_colors"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_color'" (click)="doPageNavigation('lab_color')" [text]="'My Colors'"></clics-icon></li>
<!--         <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lab_app'" (click)="doPageNavigation('lab_app')" [text]="'Apps'">Apps</clics-icon></li>-->
<!--         <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='create_app'" (click)="doPageNavigation('create_app')" [text]="'Create App'"></clics-icon></li>-->
<!--         <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='create_color'" (click)="doPageNavigation('create_color')" [text]="'Create Color'"></clics-icon></li>-->
      </ul>
    </div>
    <div class="page-links" id="links4lookbook" *ngIf="current_root == 'lookbook'">
      <ul>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_salon'" (click)="doPageNavigation('lookbook_salon')" [text]="'Salons'"></clics-icon></li>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_apps'" (click)="doPageNavigation('lookbook_apps')" [text]="'Apps'"></clics-icon></li>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_clients'" (click)="doPageNavigation('lookbook_clients')" [text]="'Clients'"></clics-icon></li>
<!--    <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_colors'" (click)="doPageNavigation('lookbook_colors')" [text]="'Colors'"></clics-icon> </li>-->
<!--    <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_following'" (click)="doPageNavigation('lookbook_following')" [text]="'Following'"></clics-icon></li>-->
<!--    <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_trending'" (click)="doPageNavigation('lookbook_trending')" [text]="'Trending'"></clics-icon></li>-->
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='lookbook_cover'" (click)="doPageNavigation('lookbook_cover')" [text]="'My Cover'">My Cover</clics-icon></li>
      </ul>
    </div>
    <div class="page-links" id="links4admin" *ngIf="current_root == 'admin'">
      <ul>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_profile'" (click)="doPageNavigation('admin_profile', true, true)" [text]="'Settings'"></clics-icon></li>
        <li *ngIf="permissions.service_settings"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_service'" (click)="doPageNavigation('admin_service', true, true)" [text]="'Service'"></clics-icon></li>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_inventory'" (click)="doPageNavigation('admin_inventory', true, true)" [text]="'Inventory'"></clics-icon></li>
<!--        <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_dispenser'" (click)="doPageNavigation('admin_dispenser')" [text]="'Dispenser'"></clics-icon></li>-->
<!--        <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_equipment'" (click)="doPageNavigation('admin_equipment')" [text]="'Equipment'"></clics-icon></li>-->
<!--        <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_lookbook'" (click)="doPageNavigation('admin_lookbook')" [text]="'Lookbook'"></clics-icon></li>-->
<!--        <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_salon'" (click)="doPageNavigation('admin_salon')" [text]="'Salon'"></clics-icon></li>-->
<!--        <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='admin_stylists'" (click)="doPageNavigation('admin_stylists')" [text]="'Stylists'"></clics-icon></li>-->
      </ul>
    </div>
    <div class="page-links" id="links4reports" *ngIf="current_root == 'reports'">
      <ul>
        <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='report_clients'" (click)="doPageNavigation('report_clients', true)" [text]="'Clients'"></clics-icon></li>
        <li *ngIf="manager_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='report_inventory'" (click)="doPageNavigation('report_inventory')" [text]="'Inventory'"></clics-icon></li>
        <li *ngIf="manager_view || renter_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='report_register'" (click)="doPageNavigation('report_register', true)" [text]="'Register'"></clics-icon></li>
        <li *ngIf="manager_view || renter_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='report_billing'" (click)="doPageNavigation('report_billing', true)" [text]="'Invoices'"></clics-icon></li>
<!--    <li><a [theme]="'dark'" [active]="activePage=='report_ecology'" (click)="doPageNavigation('report_ecology')" [text]="'Ecology'"></a></li>-->
<!--    <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='report_lookbook'" (click)="doPageNavigation('report_lookbook')" [text]="'Lookbook'"></clics-icon></li>-->
      </ul>
    </div>
    <div class="page-links" id="lnks4clients" *ngIf="current_root == 'clients'">
      <div *ngIf="clientIsSelected; else noClientNavigation">
        <ul>
          <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='clients'" (click)="doPageNavigation('clients', true)" [text]="'List'">List</clics-icon></li>
          <li *ngIf="permissions.access_profile"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='profile'" (click)="doPageNavigation('profile')" [text]="'Profile'"></clics-icon></li>
          <li *ngIf="full_view"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='application'" (click)="doPageNavigation('application')" [text]="'Application'"></clics-icon></li>
          <li *ngIf="permissions.access_history"><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='history'" (click)="doPageNavigation('history')" [text]="'History'"></clics-icon></li>
<!--      <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='pics'" (click)="doPageNavigation('pics')" [text]="'Pics'"></clics-icon></li>-->
        </ul>
      </div>
      <ng-template #noClientNavigation>
        <ul>
          <li><clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" [theme]="'dark'" [active]="activePage=='clients'" (click)="doPageNavigation('clients', true)" [text]="'List'"></clics-icon></li>
        </ul>
      </ng-template>
    </div>
    <div id="links4create" *ngIf="current_root == 'createmenu'">
      <ul>
        <li><clics-icon [class.active]="useConversionLibrary == false" (click)="chooseConversion(false);" [text]="'CLICS Colors<sup>&reg;</sup>'"></clics-icon></li>
        <li><clics-icon [class.active]="useConversionLibrary" (click)="chooseConversion(true);" [text]="'Comparisons'"></clics-icon></li>
      </ul>
    </div>
  </div>
</div>
