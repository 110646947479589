<ion-header>
  <ion-toolbar>
    <ion-title>{{photoTitle}}</ion-title>
    <ion-buttons slot="primary">
      <ion-back-button></ion-back-button>
      <ion-button (click)="closeModal()" class="icon-only clickable">
        <ion-icon ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="photo" *ngIf="!!url">
    <img src="{{url}}" alt="client photo" />
  </div>
</ion-content>
<ion-footer>
  <div class="color_actions bottom_action" [class.single]="true" >
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="replaceable" class="clickable">
          <clics-icon [theme]="'dark'" (click)="confirmReplace()" [text]="'Replace'"></clics-icon>
        </ion-col>
        <ion-col>
          <clics-icon [theme]="'dark'" (click)="confirmProfile()" [text]="'Make Profile'"></clics-icon>
        </ion-col>
        <ion-col class="clickable">
          <clics-icon [theme]="'dark'" (click)="confirmRemove()" [text]="'Delete'"></clics-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
