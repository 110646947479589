<ion-header>
  <navigation-bar *ngIf="!isModal"></navigation-bar>
  <navigation-sidebar *ngIf="!isModal && permissions.show_create_sidebar" current_root='createmenu' (changeLibrary)="changeLibrary($event)"></navigation-sidebar>
</ion-header>
<ion-content class="ion-padding">
  <div id="collection_buttons">
    <ion-button class="clics large" [class.selected]="labTarget=='Topchic'" (click)="setLabTarget('Topchic')">Topchic</ion-button>&nbsp;&nbsp;
    <ion-button class="clics large" [class.selected]="labTarget=='Colorance'" (click)="setLabTarget('Colorance')">Colorance</ion-button>&nbsp;&nbsp;
<!--    <ion-button class="clics large" [class.selected]="labTarget=='colors'" (click)="setLabTarget('colors')">My Colors</ion-button>-->
  </div>
  <ion-list *ngIf="this.repo">
    <ion-item *ngIf="useConversionLibrary == false && !!settings.allow_all_colors">
      <ion-label (click)="addLibraryColor()">ALL COLORS</ion-label>
    </ion-item>
    <div *ngIf="!settings.use_included">
      <ion-item *ngFor="let lib of this.repo.getCreateMenuLibraries(modeCtrl.getDispenseMode(), labTarget)">
        <ion-label (click)="addLibraryColor(lib.title)" [innerHTML]="lib.title"></ion-label>
      </ion-item>
    </div>
    <div *ngIf="!!settings.use_included">
      <ion-item *ngFor="let lib of settings.included_collections">
        <ion-label (click)="addLibraryColor(lib)" [innerHTML]="lib"></ion-label>
      </ion-item>
    </div>
    <ion-item *ngIf="useConversionLibrary == false && !!settings.show_lightener">
      <ion-label (click)="promptForAdditive('Li')">Lightener</ion-label>
    </ion-item>
    <ion-item *ngIf="useConversionLibrary == false && !!settings.show_color_lock">
      <ion-label (click)="promptForAdditive('CL')">Color Lock</ion-label>
    </ion-item>
    <ion-item *ngIf="useConversionLibrary == false && !!settings.show_developer">
      <ion-label (click)="promptForDeveloper()">Developer</ion-label>
    </ion-item>
    <ion-item
      *ngIf="!!settings.show_reset_app && modeCtrl.getDispenseMode() != 'simple' && cs.formulas.length > 0 && !cs.isQueued()">
      <ion-label (click)="resetApp()">RESET APP</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <page-footer *ngIf="!isModal">&nbsp;</page-footer>
  <div class="buttons">
    <ion-button class="clics center" *ngIf="isModal" (click)="closeModal(true)">Cancel</ion-button>
  </div>
</ion-footer>
<dispense-lightener
  *ngIf="!!targetLightener"
  [title]="'Lightener'"
  [default]="targetLightenerWeight"
  [minQty]="this.minAdditive"
  [maxQty]="this.maxAdditive"
  [includeDevChoice]="this.additiveDev"
  [initialDevQty]="additiveDevAmount"
  [defaultStrength]="this.additiveDevStrength"
  [location]="additiveLocation"
  (confirmation)="saveLightenerFormula($event)"
  (cancel)="resetAdditivePrompt()">
</dispense-lightener>
<dispense-confirm
  *ngIf="!!targetColorLock"
  [title]="'Color Lock'"
  [default]="targetColorLockWeight"
  [min]="this.minAdditive"
  [max]="this.maxAdditive"
  [confirmText]="'Save'"
  (confirmation)="saveColorLockFormula($event)"
  (cancel)="resetAdditivePrompt()">
</dispense-confirm>
<confirm-developer
  *ngIf="!!targetDeveloper"
  [start]="devAmount"
  [min]="10"
  [max]="200"
  [minStrength]="permissions.gw_dev_strengths ? 10 : 1"
  (cancel)="this.targetDeveloper = null"
  (requestDispense)="doAddDeveloper($event)">
</confirm-developer>
