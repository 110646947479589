<ion-header *ngIf="requestedView != 'modal'">
  <navigation-bar></navigation-bar>
  <navigation-sidebar current_root='lab'></navigation-sidebar>
</ion-header>
<ion-header class="naked" *ngIf="requestedView == 'modal'">
  <ion-toolbar>
    <ion-title>Use Application</ion-title>
    <ion-buttons>
      <!--<ion-button class="clickable" (click)="closeModal()">Close</ion-button>-->
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <!--<record-menu class="search filter" *ngIf="apps.length > 0"></record-menu>-->
  <div *ngIf="activeLibrary">
    <div class="none_message" *ngIf="activeLibrary.applications.length == 0">
      <br>
      <h2>No apps have been loaded from the {{modeCtrl.collectionStr(true)}}</h2>
    </div>
<!--        <div class="none_message" *ngIf="activeLibrary.filteredApps.length == 0 && activeLibrary.applications.length > 0">-->
<!--            <br>-->
<!--            <h2>No apps match the selected filters</h2>-->
<!--        </div>-->
    <div class="list-group" *ngIf="activeLibrary.applications.length > 0">
      <h3 class="list-title">Applications</h3>
      <app-listing  *ngFor="let app of activeLibrary.applications" [app]="app" (openSelectedApp)="showAppDetail(app.token)"></app-listing>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="color_actions bottom_action" id="color_action_div">
    <ion-grid>
      <ion-row>
        <ion-col size="4" *ngIf="context != 'client'">
          <clics-icon [theme]="'dark'" (click)="addNewLibraryApp()" [text]="'Create'"></clics-icon>
        </ion-col>
        <ion-col size="4">&nbsp;</ion-col>
        <ion-col size="4" *ngIf="clientSelected && context == 'client'">
          <clics-icon [theme]="'dark'" (click)="cancelChooseApp()" [text]="'Cancel'"></clics-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <page-footer *ngIf="context != 'client'">&nbsp;</page-footer>
</ion-footer>
