<ion-header class="naked">
  <ion-title>Scan Damaged Canisters</ion-title>
</ion-header>
<ion-content class="ion-padding">
  <h2>Damaged Canister Scan</h2>
  <p>Please scan unusable, damaged canisters as soon as possible so we can send replacements right away. Canisters
    recorded as damaged canisters may no longer be used.</p>
  <div id="result-review" *ngIf="!!reviewList">
    <div class="inventory_report" *ngIf="!!report" [innerHTML]="report"></div>
    <ion-button class="clics" (click)="nextCanister()">Next Damaged Canister</ion-button>
    <ion-button class="clics damage-button" (click)="undoLastScan()" [class.hidden]="!!lastScanUndone">UNDO</ion-button>
    <br><br><ion-button (click)="finishScan()">Finish</ion-button>
<!--    <ion-button (click)="testScan()">TEST</ion-button>-->
  </div>
  <div id="damage-spec" *ngIf="!reviewList">
    <h3>What is damaged on this canister? Select all that apply.</h3>
    <ion-grid>
      <ion-list>
        <ion-item *ngFor="let item of damage">
          <ion-checkbox #damage_{{item.key}} [value]="item.key" (ionChange)="setDamage(item.key, $event)" mode="md"></ion-checkbox>
          <ion-label>{{item.label}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-grid>
    <div>
      <ion-button class="clics" (click)="scanDamagedCanister()" [disabled]="notReadyForScan()">Scan Damaged Canister</ion-button>&nbsp;
      <ion-button class="clics" (click)="finishScan()">Cancel</ion-button>
<!--      <ion-button (click)="testScan()">TEST</ion-button>-->
    </div>
  </div>
</ion-content>
<ion-footer>
</ion-footer>
