<!-- Generated template for the FormulaMixComponent component -->
<div>
  <div class="amount-slider-container" *ngIf="!!formula">
    <amount-slider [title]="'Total Amount'" [amount]=totalAmount [restrictMax]=true [min]=minAllowableTotal
                   [noGradient]=true (onChange)="handleAdjustTotal($event)" *ngIf="initialized" [unit]=unit></amount-slider>
    <div class="hr" *ngIf="initialized" [class.tight]="!includeDev">&nbsp;</div>
    <div class="ratio_buttons" *ngIf="initialized && includeDev">
      <ion-button size="small" class="clics" [class.selected]="workingFormula.devRatio() == 1.0" (click)="makeRatio(1)">1:1</ion-button>
      <ion-button size="small" class="clics" [class.selected]="workingFormula.devRatio() == 2.0" (click)="makeRatio(2)">2:1</ion-button>
      <ion-button size="small" class="clics" [class.selected]="isCustomRatio()" (click)="openCustomRatio()">Custom{{customRatioStr()}}</ion-button>
    </div>
  </div>
  <div class="dispense-product" *ngIf="includeDev">
    <div class="amount-string dev-strength">
      Developer Strength: <span>{{devStrength}}</span>
    </div>
    <div class="amount-slider-container strength-slider">
      <amount-slider #strengthSlide [noGradient]=true [showTitle]=false [min]=minDevStrength [max]=40 [restrictMax]=true
                     [amount]=devStrength [unit]=unit
                     (onChange)="handleAdjustStrength($event)"></amount-slider>
      <div class="strength_buttons" *ngIf="initialized && includeDev">
        <ion-button *ngIf="!permissions.gw_dev_strengths" size="small" class="clics" [class.selected]="matchesDevStrength(5)" (click)="setStrength(5)">
          5
        </ion-button>
        <ion-button size="small" class="clics" [class.selected]="matchesDevStrength(10)" (click)="setStrength(10)">10</ion-button>
        <ion-button size="small" class="clics" [class.selected]="matchesDevStrength(20)" (click)="setStrength(20)">20</ion-button>
        <ion-button size="small" class="clics" [class.selected]="matchesDevStrength(30)" (click)="setStrength(30)">30</ion-button>
        <ion-button size="small" class="clics" [class.selected]="matchesDevStrength(40)" (click)="setStrength(40)">40</ion-button>
      </div>
    </div>
  </div>
  <ion-list no-margin no-padding class="include-developer">
    <ion-item lines="none">
      <ion-label>Include Developer</ion-label>
      <ion-toggle [(ngModel)]="includeDev" (ionChange)="handleDevToggle()"></ion-toggle>
    </ion-item>
  </ion-list>
</div>
<div class="customer-ratio-wrapper" [class.active]="showCustomRatio">
  <div class="custom-ratio-content">
        <amount-slider #colorSlider [title]="'Color'" [amount]=colorAmount [restrictMax]=true
                       [noGradient]=true (onChange)="handleAdjustColor($event)" *ngIf="initialized"
                       [min]=this.minAllowableColor [max]=this.maxAllowableColor [unit]=unit class="space-after"></amount-slider>
        <amount-slider #devSlider [title]="developerTitle()" [amount]=devAmount [restrictMax]=true
                       [noGradient]=true (onChange)="handleAdjustDev($event)" *ngIf="initialized"
                       [min]=minAllowableDev [max]=maxAllowableDev [unit]=unit></amount-slider>
        <p *ngIf="atMinWeight()">minimum weight for this formula: {{minAllowable}}{{unit}}</p>
    <div class="ratio_buttons" *ngIf="initialized && includeDev">
      <ion-button size="small" class="clics" (click)="makeRatio(1)">1:1</ion-button>
      <ion-button size="small" class="clics" (click)="makeRatio(2)">2:1</ion-button>
    </div>
    <hr>
    <div class="action_buttons">
<!--      <ion-button size="small" class="clics" (click)="closeCustomRatio(true)">Cancel</ion-button>-->
      <ion-button size="small" class="clics" (click)="closeCustomRatio()">Done</ion-button>
    </div>
  </div>
</div>
