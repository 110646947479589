<ion-header>
  <ion-toolbar>
    <ion-title>Application Details</ion-title>
    <ion-buttons slot="primary">
      <ion-back-button></ion-back-button>
      <ion-button (click)="closeModal()" class="icon-only clickable">
        <ion-icon ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <h2>{{app_type.name}}</h2>
  <p>{{app_type.description}}</p>
  <div class="steps_content" [innerHTML]="app_type.steps"></div>
</ion-content>
