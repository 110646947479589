<ion-header *ngIf="requestedView != 'modal'">
  <navigation-bar></navigation-bar>
  <navigation-sidebar current_root='lab'></navigation-sidebar>
</ion-header>
<ion-header class="naked" *ngIf="requestedView == 'modal'">
  <ion-title>Add Conversion Formula</ion-title>
</ion-header>
<ion-content>
  <div class="ion-padding">
    <div class="selection" *ngIf="ready">
      <div class="choice">
        <div class="choice-name" (click)="chooseConversionManufacturer()" [innerHTML]="manufacturerName"></div>
        <ion-icon name="chevron-down-outline" (click)="chooseConversionManufacturer()"></ion-icon>
      </div>
      <div class="choice" *ngIf="!!manufacturerId && !!libraryTitle">
        <div class="choice-name" (click)="chooseLibrary()" [innerHTML]="libraryTitle"></div>
        <ion-icon name="chevron-down-outline" (click)="chooseLibrary()"></ion-icon>
      </div>
    </div>
    <div class="color_library" id="color_library_div" *ngIf="ready && !!library && library.formulas.length > 0">
      <div class="library_count">{{library.formulas.length}}</div>
      <div class="color-grid">
        <color-grid-image
          *ngFor="let formula of library.formulas"
          [formula]="formula" [content]="content"
          (selectFormula)="selectFormula(formula.token)" (inspectFormula)="this.showColorDetail(formula.token)"></color-grid-image>
      </div>
    </div>
    <div class="color_library" *ngIf="ready && !!library && library.formulas.length == 0">
      <br><br>
      <h3>There are no conversion formulas in this {{modeCtrl.collectionStr(true)}}</h3>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="bottom_action single">
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="anySelected == false">
         <clics-icon [theme]="'dark'" class="disabled" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="anySelected && requestedView != 'modal'">
         <clics-icon [theme]="'dark'" (click)="confirmUseColor()" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="anySelected && requestedView == 'modal'">
         <clics-icon [theme]="'dark'" (click)="addColorsToTargetApp()" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!anySelected && requestedView != 'modal'">
         <clics-icon [theme]="'dark'" class="disabled" [text]="'Reset'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="anySelected && requestedView != 'modal'">
         <clics-icon [theme]="'dark'" (click)="clearSelection()" [text]="'Reset'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="requestedView == 'modal'">
         <clics-icon [theme]="'dark'" (click)="cancelChooseColor()" [text]="'Reset'"></clics-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <page-footer *ngIf="requestedView != 'modal'">&nbsp;</page-footer>
</ion-footer>
