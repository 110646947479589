<div class="navigation-bar-container">
    <div id="page-logo">
      <a id="app-icon" (click)="doNavigation('home')">
        <img src="assets/images/{{this.logoImage()}}" alt="CLICS logo" (click)="goHomePage()"
             *ngIf="!stagingSelected()">
        <img src="assets/images/CLICS_2020_logo_hdr_STAGING.png" alt="CLICS logo" (click)="goHomePage()"
             *ngIf="stagingSelected()">
      </a>
    </div>
    <div class="icons">
     <clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" *ngIf="!modeCtrl.isSimpleMode()" [theme]="theme" class="navbar" [special]="nav_button_active['clients']" (click)="doNavigation('clients')" [text]="'CLIENTS@nav'"></clics-icon>
     <clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" *ngIf="!modeCtrl.isSimpleMode()" [theme]="theme" class="navbar" [special]="nav_button_active['lab']" (click)="doNavigation('lab')" [text]="'DESIGN@nav'"></clics-icon>
     <clics-icon [gw]="clicsService.mobileAppType() == 'goldwell'" *ngIf="!modeCtrl.isSimpleMode()" [theme]="theme" class="navbar" [special]="nav_button_active['dispenser']" (click)="doNavigation('dispenser')" [text]="'DISPENSER'"></clics-icon>
    </div>
</div>
