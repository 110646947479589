import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import {Injectable, Inject, InjectionToken, ErrorHandler} from '@angular/core';

const rollbarConfig = {
    accessToken: 'b63f878efa1b4bfab4e5a95aa6b87a82',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        // environment: "{YOUR ENVIRONMENT}",
        client: {
            javascript: {
                source_map_enabled: true, // true by default

                // -- Add this into your configuration ---
                code_version: "3.3.30",
                // ---------------------------------------
                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true
            }
        }
    }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) public rollbar: Rollbar) {}

    handleError(err:any) : void {
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
