<div class="app-formula-container">
  <div class="app-location">
    <div class="top-line">
      <div class="name" (click)="doColorDetail(formula.token)">
        <span *ngIf="showProductLine">{{ formula.productLineStr() }} </span>
        {{ formula.titleString(25) }}
        <clics-icon *ngIf="showPedigree && !!formula.pedigree" [theme]="'dark'" class="pedigree" [iconOnly]="true" [special]="true" [text]="formula.pedigreeText()"></clics-icon>
        <clics-icon *ngIf="showPedigree && !!formula.pedigreeSoftChange()" [theme]="'dark'" class="soft-change" [iconOnly]="true" [special]="true" [text]="'SOFT'"></clics-icon>
      </div>
      <div class="amount-string" *ngIf="amountIsValid()">
        {{ amountStr() }}
      </div>
    </div>

    <div class="mid-line">
      <div class="location">
        <span>{{ formula.locationName() }}</span>
      </div>
      <div class="coverage" *ngIf="formula.isFormulaRequest()">
        <span class="dev-iconNOSTYLE" *ngIf="formula.hasDeveloper()">{{ 'D' + formula.developerStrength() }}</span>
        <span class="dev-iconNOSTYLE" *ngIf="showDevRatio && formula.hasDeveloper() && !formula.isDeveloperOnly()">{{ ' ' + formula.developerRatio() + ':1' }}</span>
        <span class="dev-iconNOSTYLE" *ngIf="permissions.show_formula_info && formula.hasDeveloper() && !formula.isDeveloperOnly()">, </span>
        <span class="coverage" *ngIf="permissions.show_formula_info && !formula.isDeveloperOnly()">{{ formula.info }}</span>
      </div>
    </div>

    <div class="image">
      <div class="color_swatch" [style.backgroundColor]=formula.rgb (click)="doColorDetail(formula.token)"></div>
    </div>
    <div class="timers" *ngIf="!!formula.appSummary">
      {{ formula.appSummary }}
    </div>
  </div>
</div>
