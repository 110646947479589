// import {HttpClient} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {CLiCSService} from '../clics.service';
import set = Reflect.set;

/*
  Keeps track of the current user's setting choices and [future] syncs these with the back-end.
*/
@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {
  clicsService: any;

  // Note: settings switches now instantiated from settingNames
  settings: any = {};

  // Title translations
  settingNames: any = [
    // {category: 'Reminders', title: 'Profile Update Reminder', key: 'prof_rmnd', permission: null},
    // {category: 'Reminders', title: 'Consultation Update Reminder', key: 'consult_rmnd', permission: null},
    {category: 'Timers', title: 'Elapsed Timer', key: 'elaps_tmr', default: true, permission: 'show_elapsed_timer'},
    {category: 'Timers', title: 'Rinse Timer', key: 'rinse_tmr', default: false, permission: 'show_rinse_timer'},
    {category: 'Timers', title: 'Rinse Timer Alarm', key: 'rinse_alarm', default: false, permission: null},
    {category: 'Timers', title: 'Apply/Processing Timer', key: 'proc_tmr', default: false, permission: null},
    // {category: 'Pics', title: 'Before Pics', key: 'before_pix', default: true, permission: null},
    // {category: 'Pics', title: 'After Pics', key: 'after_pics', default: true, permission: null},
    // {category: 'Other', title: 'Step Indicator', key: 'steps_sho', default: true, permission: null},
    {category: 'Formulas', title: 'Use Location', key: 'loc_sho', default: false, permission: 'use_location'},
    {category: 'Formulas', title: 'Customize Formulas', key: 'cust_sho', default: false, permission: 'customize'},
    // {category: 'Formulas', title: 'Use CoBonder by Default', key: 'use_cob', default: false, permission: null},
    {category: 'Dispense', title: 'Take Before/After Photos', key: 'befr_aftr', default: false, permission: null}
  ];

  // Loads settings from memory and loads overrides from branding.json
  constructor(private injector: Injector) {
    this.clicsService = this.injector.get(CLiCSService);
    this._assertSettings();
    this.loadFromLocal();
  }

  loadFromLocal_OLD() {
    let val = localStorage.getItem('user_settings');
    if (val != null && val != 'null')
      this.settings = JSON.parse(val);
  }

  // Load from local, preserving keys
  loadFromLocal() {
    const val = localStorage.getItem('user_settings');
    let modified = false;
    if (val != null && val != 'null') {
      const localSettings = JSON.parse(val);
      for (let setting of this.settingNames) {
        if (this.settings[setting.key] == undefined) {
          this.settings[setting.key] = setting.default;
          modified = true;
        }
        if (localSettings[setting.key] != undefined) {
          if (this.settings[setting.key] != localSettings[setting.key]) {
            this.settings[setting.key] = localSettings[setting.key];
            modified = true;
          }
        }
      }
    }
    if (modified) {
      this.saveToLocal();
    }
  }

  _assertSettings() {
    for (const item of this.settingNames) {
      if (this.settings[item.key] == undefined) {
        this.settings[item.key] = item.default;
      }
    }
  }

  saveToLocal() {
    this._assertSettings();  // Make sure all settings are present before saving
    localStorage.setItem('user_settings', JSON.stringify(this.settings));
  }

  // Find a setting value by name
  getSetting(settingName: string) {
    if (settingName in this.settings)
      return (this.settings[settingName]);
    else
      return null;
  }

  // Update a setting value
  setValue(settingKey: string, newValue: any) {
    if (settingKey in this.settings) {
      this.settings[settingKey] = newValue;
      this.saveToLocal();
      return (this.settings[settingKey]);
    } else
      return null;
  }

  // Returns a list of settings with categories included (default) or excluded (set exclude = true)
  getSettings(categories: string[]= [], exclude: boolean = false): any[] {
    let result = [];

    for (let setting of this.settingNames) {
      if (!!exclude) {
        if (!categories.includes(setting.category)) {
          setting.val = this.settings[setting.key];
          result.push(setting);
        }
      } else {
        if (categories.includes(setting.category)) {
          setting.val = this.settings[setting.key];
          result.push(setting);
        }
      }
    }

    return result;
  }

}
