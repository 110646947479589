<ion-header class="naked">
  <ion-toolbar>
    <ion-title>
      SUPPORT TOPIC
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss(true)">
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list>
    <ion-radio-group [value]="this.category" (ionChange)="updateTopic($event)">
      <ion-list-header>
        <ion-label>Please choose a topic for your support request...</ion-label>
      </ion-list-header><br>
      <ion-item>
        <ion-label>Mobile App How-To</ion-label>
        <ion-radio slot="start" value="mobile_app_howto" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>Formula/Education</ion-label>
        <ion-radio slot="start" value="formula_education" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>Technical Support</ion-label>
        <ion-radio slot="start" value="technical_support" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>Inventory</ion-label>
        <ion-radio slot="start" value="inventory" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>Billing/Invoices</ion-label>
        <ion-radio slot="start" value="billing_invoices" mode="md"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>General/Other</ion-label>
        <ion-radio slot="start" value="general_other" mode="md"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list><br>
  <ion-button class="clics"(click)="dismiss()">CONTINUE</ion-button>
</ion-content>
<ion-footer></ion-footer>
