<div class='color-tile-image full-width' [style.background-color]="backgroundColor" (click)="toggleTexture()">
  <img src="assets/images/swatch-txtr5-long-level0.png" alt="hair texture image" *ngIf="showTexture && level == 0">
  <img src="assets/images/swatch-txtr5-long-level1.png" alt="hair texture image" *ngIf="showTexture && level == 1">
  <img src="assets/images/swatch-txtr5-long-level2.png" alt="hair texture image" *ngIf="showTexture && level == 2">
  <img src="assets/images/swatch-txtr5-long-level3.png" alt="hair texture image" *ngIf="showTexture && level == 3">
  <img src="assets/images/swatch-txtr5-long-level4.png" alt="hair texture image" *ngIf="showTexture && level == 4">
  <img src="assets/images/swatch-txtr5-long-level5.png" alt="hair texture image" *ngIf="showTexture && level == 5">
  <img src="assets/images/swatch-txtr5-long-level6.png" alt="hair texture image" *ngIf="showTexture && level == 6">
  <img src="assets/images/swatch-txtr5-long-level7.png" alt="hair texture image" *ngIf="showTexture && level == 7">
  <img src="assets/images/swatch-txtr5-long-level8.png" alt="hair texture image" *ngIf="showTexture && level == 8">
  <img src="assets/images/swatch-txtr5-long-level9.png" alt="hair texture image" *ngIf="showTexture && level == 9">
  <img src="assets/images/swatch-txtr5-long-level10.png" alt="hair texture image" *ngIf="showTexture && level == 10">
  <img src="assets/images/swatch-txtr5-long-level11.png" alt="hair texture image" *ngIf="showTexture && level > 10">
</div>
