<div class="amount-slider-main">
  <div class="amount-title" *ngIf="showTitle">
    <div class="content amount-label">{{ title }}</div>
    <div class="content amount-display">{{ amount }}{{ unit }}</div>
  </div>

  <div class="amount-slider" [class.no-background]="noGradient">
    <div class="amount-string">
      <ion-range [(ngModel)]="amount" [min]="min" [max]="max" (ionChange)="onAmountChange()"
                 class="amount-slider-range"></ion-range>
    </div>
    <div class="up-down-buttons">
      <div class="amount-slider-button"
           (touchstart)="startIncrement()"
           (touchend)="endIncrement()"
           (mousedown)="increment()"
           (mouseup)="endIncrement()" >
        <div class="img">&nbsp;</div>
      </div>
      <div class="amount-slider-button bottom"
           (touchstart)="startDecrement()"
           (touchend)="endDecrement()"
           (mousedown)="decrement()"
           (mouseup)="endDecrement()" >
        <div class="img">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
