<!--<ion-header class="naked" lines="none">-->
<!--  <ion-title>Set Formula Options</ion-title>-->
<!--</ion-header>-->
<ion-content class="ion-padding">
  <div>
    <div class="component-selection" *ngIf="!!settings">
      <h2 *ngIf="formula && formula.title">{{formula.title}}</h2>
      <div class="pill-box">
        <color-pill [formula]="formula"></color-pill>
      </div>
      <p class="disclaimer">{{mixingBowl.screenDisclaimer}}</p>
      <div *ngIf="!!permissions && permissions.customize && settings.cust_sho" id="customize-button" class="clickable customize">
        <ion-button class="clics" size="small" (click)="doCustomize()">customize</ion-button>
      </div>
      <div class="location-container" *ngIf="permissions.use_location && settings.loc_sho">
        <ion-button class="clics" id="location_select" size="small" [class.unselected]="location!=''" (click)="chooseLocation()">{{locationButtonText}}</ion-button>
      </div>
    </div>
    <div class="formula-mix">
      <formula-mix #formulaMixer *ngIf="!!formula" [formula]="formula" [mixingBowl]="mixingBowl" [minDevStrength]="permissions.gw_dev_strengths ? 10 : 1"></formula-mix>
    </div>
  </div>

  <div class="color-options tight" *ngIf="showCoverage && !!permissions.show_coverage_choices">
    <ion-list lines="none">
      <ion-radio-group class="coverage" [value]="coverage" (ionChange)="this.updateCoverage($event)">
        <ion-item class="clics">
          <ion-label class="clics">PERM</ion-label>
          <ion-radio class="clics" slot="start" value="perm" mode="md"></ion-radio>
        </ion-item>
        <ion-item class="clics">
          <ion-label class="clics">DEMI</ion-label>
          <ion-radio class="clics" slot="start" value="demi" mode="md"></ion-radio>
        </ion-item>
        <ion-item class="clics">
          <ion-label class="clics">TONER</ion-label>
          <ion-radio class="clics" slot="start" value="toner" mode="md"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </div>
</ion-content>
<ion-footer>
  <div class="color-options">
    <div class="option">
      <ion-button class="clics toplevel" (click)="cancelChanges()">Cancel</ion-button>
      <ion-button class="clics toplevel" (click)="saveChanges()" *ngIf="!fast">Use</ion-button>
      <ion-button class="clics toplevel" (click)="saveChanges()" *ngIf="!!fast">Dispense</ion-button>
      <div class="cobonder" *ngIf="!!permissions.show_cobonder_choice">
        <ion-label>CoBonder</ion-label>
        <ion-toggle [checked]="cobonded" (ionChange)="updateCobonded($event)" class="cobonder-toggle"></ion-toggle>
      </div>
    </div>
  </div>
</ion-footer>
