<ion-header class="naked">
  <ion-toolbar>
    <ion-title>Create Application</ion-title>
    <ion-buttons slot="primary">
      <ion-button class="icon-only clickable" (click)="closeModal()">
        <ion-icon ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <record-menu *ngIf="editMode == true" class="edit" (editItem)="setEditMode(false)"></record-menu>
  <div class="app-settings" id="edit_mode_settings">
    <ion-grid>
      <ion-row>
        <ion-col>
          <label for="name-input">Descriptive name</label>
          <input class="android" #nameInput id="name-input" [value]="title" (keydown)="closeKbdIfNameDone($event)" (keyup)="updateAppTitle()"/>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label>Type</ion-label>
            <ion-select interface="action-sheet" [value]="this.appTypeValue" (ionChange)="setAppType($event)">
              <ion-select-option *ngFor="let app_type of this.mixingBowl.app_types" value="{{app_type.ident}}">{{app_type.name}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label>Starting Level</ion-label>
            <ion-select interface="action-sheet" [value]="this.startingLevel" (ionChange)="setStartingLevel($event)">
              <ion-select-option *ngFor="let lvl of this.levels" value="{{lvl.value}}">{{lvl.key}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="client_application">
      <ion-list reorder="true" (ionItemReorder)="reorderItems($event)">
        <ion-item-sliding *ngFor="let formula of this.formulas">
          <ion-item reorder="true">
            <app-create-formula [formula]="formula"
                                [hideAmount]="false"
                                (openFormulaChoices)="openFormulaChoices($event)"
                                (openColorDetail)="openFormulaChoices($event)">
            </app-create-formula>
          </ion-item>
          <ion-item-options side="end">
            <ion-item-option color="danger" (click)="removeFormula(formula);">Remove</ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
      <h3 class="add-color" (click)="showCreateChoice()">Add color formulas
        <ion-icon name="flask"></ion-icon>
      </h3>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="bottom_action">
    <ion-grid>
      <ion-row *ngIf="this.customizing() == true">
        <ion-col (click)="confirmReset()" class="clickable"><span *ngIf="ca != null && ! ca.isBlank()">Start Over</span>
        </ion-col>
        <ion-col class="clickable" (click)="updateCustomize()"><span>Use</span></ion-col>
        <ion-col class="clickable" (click)="confirmCancel()"><span>Cancel</span></ion-col>
      </ion-row>
      <ion-row *ngIf="this.customizing() == false">
        <ion-col (click)="confirmReset()" class="clickable" *ngIf="ca != null && ! ca.isBlank()"><span>Start Over</span>
        </ion-col>
        <ion-col class="disabled" *ngIf="ca == null || ca.isBlank()"><span>Start Over</span></ion-col>
        <ion-col class="clickable" *ngIf="context != 'library' && readyToUse()" (click)="doUseApp(false)">
          <span>Use</span></ion-col>
        <ion-col class="disabled" *ngIf="context != 'library' && !readyToUse()"><span>Use</span></ion-col>
        <ion-col class="clickable" *ngIf="context != 'client' && readyToSave()" (click)="chooseLibrary()">
          <span>Save</span></ion-col>
        <ion-col class="disabled" *ngIf="context != 'client' && !readyToSave()"><span>Save</span></ion-col>
        <ion-col class="clickable" (click)="confirmCancel()"><span>Cancel</span></ion-col>
      </ion-row>
      <ion-row *ngIf="this.customizing() == false">
        <ion-col><span>&nbsp;</span></ion-col>
        <ion-col class="disabled"><span>Add Step</span></ion-col>
        <ion-col><span>&nbsp;</span></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
