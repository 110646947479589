<ion-header class="naked">
  <ion-title>Confirm Location</ion-title>
</ion-header>
<ion-content class="ion-padding">
  <h3>Confirming Salon: <span>{{clicsService.current_salon.name}}</span></h3>
  <div *ngIf="!fakeIt">
    <p>Go to any CLICS dispenser at this salon, touch the screen, select your name, then click on the button below to
      scan the barcode shown on the dispenser screen.</p>
    <p>If your name is not listed or no barcode is show on the dispenser please click on Cancel and try again.</p>
    <ion-button (click)="scanValidationBarcode()">Scan Dispenser Barcode</ion-button>
  </div><br><br><br>
  <div *ngIf="!!fakeIt">
    <h3>Are you presently at {{clicsService.current_salon.name}}?</h3>
    <ion-button class="clics" (click)="fakeConfirmation()">Yes</ion-button>&nbsp;
    <ion-button class="clics" (click)="chooseSalon()">No</ion-button>&nbsp;
    <ion-button class="clics" (click)="cancelConfirmation()">Cancel</ion-button>
  </div>
</ion-content>
<ion-footer>
</ion-footer>
