<div class="modal-backdrop" #dispense_lightener_modal>
  <div class="modal-content" id="dispense_confirm_div">
    <h2>{{localTitle}}</h2>
    <h3>{{qtyString}} total</h3>
    <div class="amount-slider-container">
      <amount-slider (onChange)="updateTotalQty($event)" [amount]=totalQty [showTitle]=false [min]=minQty [max]=maxQty [restrictMax]=true [noGradient]=true [unit]=unit></amount-slider>
      <p *ngIf="atMinWeight()">{{minWeightText}}: {{minQty}}{{unit}}</p>
    </div>

    <div class="option-switch" *ngIf="!!option">
      <ion-list lines="none">
        <ion-item>
          <ion-label>{{option}}</ion-label>
          <ion-toggle [(ngModel)]="includeDevChoice" (ionChange)="updateAll()"></ion-toggle>
        </ion-item>
      </ion-list>
    </div>
    <div id="developer_adjust" *ngIf="!!option && !!includeDevChoice">
      <div class="option-modifier">
        <h4>{{devStrengthStr}}</h4>
        <amount-slider [min]=1 [max]=40 [amount]=devStrength [showTitle]=false [restrictMax]=true [noGradient]=true (onChange)="updateDevStrength($event)"></amount-slider>
      </div>
      <div class="option-modifier">
        <h4>{{devPctStr}}</h4>
        <amount-slider [min]=devMinQty [max]=devMaxQty [amount]=devQty [showTitle]=false [restrictMax]=true [noGradient]=true (onChange)="updateDevQty($event)"></amount-slider>
      </div>
    </div>
    <div class="location-container" *ngIf="!!option && !!locations && showLocation">
      <ion-list lines="none">
        <ion-item>
          <ion-label>Location</ion-label>
          <ion-select [(ngModel)]="location">
            <ion-select-option *ngFor="let loc of this.locations" value={{loc.value}}>{{loc.label}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>
    <div class="confirm-buttons">
      <ion-button class="clics" (click)="doCancel()">cancel</ion-button>
      <ion-button class="clics" (click)="doDispense()">{{confirmText}}</ion-button>
    </div>
  </div>
</div>
