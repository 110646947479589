import {Component, ViewChildren, QueryList, Input, ViewChild, Injector} from '@angular/core';
import {AlertController, ActionSheetController, ModalController, IonSelect, ToastController, NavParams, LoadingController} from '@ionic/angular';
import {TapticEngine} from '@awesome-cordova-plugins/taptic-engine/ngx';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {Subscription} from 'rxjs';
import {MixingBowlProvider} from '../../services/mixing-bowl/mixing-bowl';
import {CLiCSService} from '../../services/clics.service';
import {EditQueueProvider} from '../../services/edit-queue/edit-queue';
import {EventsService} from '../../services/events/events.service';
import {SettingsProvider} from '../../services/settings/settings';
import {CLiCSColorFormula} from '../../../lib/color-formula';
import {ModeControllerProvider} from '../../services/mode-controller/mode-controller';
import {CLiCSUser} from '../../../lib/user';
import {EditQueueItem} from '../../../lib/edit-queue-item';
import {FormulaIngredientComponent} from '../../components/formula-ingredient/formula-ingredient';
import {CLiCSLibrary} from '../../../lib/library';
import {ColorFormulaToRGB} from 'colorformulatorgb/ColorFormulaToRGB';

/**
 * Color creation page
 */
@Component({
  selector: 'page-create-color',
  templateUrl: 'create-color.html',
  styleUrls: ['create-color.scss'],
})
export class CreateColorPage {
  context: string = 'any'; // client or library (where is this launched from)

  color: CLiCSColorFormula = null;
  refColor: CLiCSColorFormula = null;
  library_token: string = null;
  library_title: string = null;
  theme: string = 'light';

  new_component: string = null;
  params: any = [];
  tileColor: string = '#ffffff';
  tileLevel: number = 6;

  loading: boolean = false;  // Indicates page is loading (block component changes)
  dirty: boolean = false;  // Was the formula changed?
  showNameInput: boolean = false;  // Display name input

  loader: any = null;  // Loading spinner

  pageTitle: string = "Design Color";
  nameLabel: string = "name it";

  promptForSwatch: boolean = false;
  promptForDeveloper: boolean = false;

  levels: string = "0|.5|1|1.5|2|2.5|3|3.5|4|4.5|5|5.5|6|6.5|7|7.5|8|8.5|9|9.5|10|10.5|11|11.5|12";

  editItem: EditQueueItem = null;
  editCSitem: EditQueueItem = null;   // if a CS item is passed instead of a color to be modified

  developerRatio: number = 1.0;       // Ratio of developer to sum of tonal components
  developerRatioStr: string = '1';

  warningShown: boolean = false;

  modalDismissSubscription: Subscription = null;
  colorFormulaToRGB: any = null;

  @Input() title: string;
  @ViewChildren(IonSelect) selectors: QueryList<IonSelect>;
  @ViewChildren(FormulaIngredientComponent) ingredients: QueryList<FormulaIngredientComponent>;
  @ViewChild('titleInput') titleFormInput;

  // options for ion-select controls
  baseOptions: any = null;
  warmOptions: any = null;
  coolOptions: any = null;
  devOptions: any = null;

  constructor(public mixingBowl: MixingBowlProvider,
              private events: EventsService,
              private alertCtrl: AlertController,
              private loadingCtrl: LoadingController,
              private actionSheetCtrl: ActionSheetController,
              private modalCtrl: ModalController,
              private toastCtrl: ToastController,
              private clicsService: CLiCSService,
              private keyboard: Keyboard,
              private taptic: TapticEngine,
              private injector: Injector,
              private editQueue: EditQueueProvider,
              private settingCtrl: SettingsProvider,
              private navParams: NavParams,
              public modeCtrl: ModeControllerProvider) {
    this.colorFormulaToRGB = this.injector.get(ColorFormulaToRGB);
  }

  // Get "current" (or new) formula object from clicsService
  ionViewWillEnter() {
    this.loading = true;
    this.theme = this.clicsService.getTheme();
    if (this.modeCtrl.modeRedirect('create') == false) {
      this.context = this.navParams.get('context');
      const resetOnEnter = this.navParams.get('clear');
      this.warningShown = false;

      this.mixingBowl.assertProducts();

      // Load ColorSession or color and product array
      if (resetOnEnter === true) {
        this.color = new CLiCSColorFormula();
        this.refColor = new CLiCSColorFormula(); // For reset or comparison
        this.updateLocalParams();
        this._updateColorTile(this.params);
        this.color.assertLocalIdent();
      } else {
        // Get color to be edited from stash (previously used EditQueue)
        const stash = this.clicsService.getStash();
        if (!!stash.cf) {
          this.color = stash.cf;
          this.refColor = stash.cf;
        } else {
          if (!!stash.ca) {
            this.color = new CLiCSColorFormula();
            this.refColor = new CLiCSColorFormula();
            this.color.assertLocalIdent();
          } else {
            this.color = this.clicsService.getCurrentFormula();
            this.refColor = new CLiCSColorFormula(this.clicsService.getCurrentFormula());
            // NOTE: we always added "(copy)" to the title but if we're editing it in place we want to show the name
            if (this.color.mode == 'library' && this.color.title != null && this.color.title != '' && !this.color.title.includes('(copy)'))
              this.color.title = this.color.title + " (copy)";
          }
        }
      }

      // this.color.setMode('basic');  // If editing the color formula we no longer reference the library formula
      this.color.convertCompNameToCompOrig(this.mixingBowl);
      this.color.removeParamsByType('compName');  // Prevent duplicate parameters in editor!
      this.refColor.convertCompNameToCompOrig(this.mixingBowl);
      this.refColor.removeParamsByType('compName');  // Prevent duplicate parameters in editor!
      this.updateDeveloperRatio();  // Sets local ratio of developer to tonal components, may change later
      this.updateLocalParams();
      this._updateColorTile(this.params);
      this.dirty = false;
      this.setPageTitle();

      this.modalDismissSubscription = this.events.subscribe("modal:dismiss", () => {
        if (this.context && (this.context != 'any' && this.context != ''))
          this.modalCtrl.dismiss({modified: false, formulaUnchanged: true});
      });
    }
  }

  ionViewDidEnter() {
    this.updateLocalParams();
    if (this.params && this.params.length > 0)
      this._updateColorTile(this.params);
    this.library_token = null;
    this.title = this.color.title;
    this.loading = false;
    console.log(`@@@ CreateColor:ionViewDidEnter this.color token is ${this.color.token}`);
  }

  ionViewDidLeave() {
    this.modalDismissSubscription = this.events.unsubscribe(this.modalDismissSubscription);
  }

  // Product direct selection by ion-select control
  handleProductDirectSelect(data: string) {
    if (data == "..." || data == undefined)
      this.new_component = null;
    else {
      this.new_component = data;
      this.taptic.selection();
      this.addFormulaComponent();
    }
  }

  // Clicking on a component family button reveals an action sheet with the product options
  async chooseComponent(compType: string) {
    let options = {
      header: `Add ${compType}`,
      cssClass: 'component-options',
      buttons: [],
    };

    const prods: any[] = this.mixingBowl.prodNameByType(compType);

    // Force an initial D20 product
    if (compType == 'Dev') {
      options.header = 'Add Developer';
      options.buttons.push({
        text: 'D20 (20 Developer)',
        cssClass: 'bold',
        handler: () => {
          this.handleProductDirectSelect('D20');
        }
      });
    }

    for (let prod of prods) {
      if (prod.name == '0N')
        prod.name = '00N';
      options.buttons.push({
        text: `${prod.name} (${prod.description})`,
        handler: () => {
          this.handleProductDirectSelect(prod.name);
        }
      });
    }

    options.buttons.push({
      text: 'Cancel',
      role: 'cancel',
      mode: 'ios',
      handler: () => {
        this.actionSheetCtrl.dismiss();
        return false;
      }
    });

    const actionSheet = await this.actionSheetCtrl.create(options);

    if (compType == 'Dev') {
      this.promptForDeveloper = true;
    } else {
      await actionSheet.present();
    }
  }

  // If a new component is a developer or base tone, remove any prior base tone or developer components. Some restrictions apply.
  removeReplacedParams(newComponent: string) {
    if (MixingBowlProvider.isDeveloperComponent(newComponent)) {
      this.ingredients.forEach((ingredient, index) => {
        if (MixingBowlProvider.isDeveloperComponent(ingredient.ingredientName))
          ingredient.deleteMe();
      });
    }
    /*       else {
                if (MixingBowlProvider.isBaseToneComponent(newComponent)) {
                    if (newComponent != '00N' && newComponent != '12N') {
                        this.ingredients.forEach((ingredient, index) => {
                            if (MixingBowlProvider.isBaseToneComponent(ingredient.ingredientName)) {
                                if (ingredient.ingredientName != '00N' && ingredient.ingredientName != '12N')
                                    ingredient.deleteMe();
                            }
                        });
                    }
                }
            }*/
  }

  // Respond to a click by adding the selected component to this page as a
  async addFormulaComponent() {
    if (this.new_component != null) {
      this.removeReplacedParams(this.new_component);
      const amount = this.mixingBowl.getDefaultCompWeight(this.new_component, this.color, this.developerRatio);
      this.color.instantiateParam('compOrig', this.new_component, amount.toString());

      this.updateLocalParams();

      // If not developer, update any developer ingredient amounts
      if (!MixingBowlProvider.isDeveloperComponent(this.new_component)) {
        this.color.adjustDeveloperByRatio(this.developerRatio);
        this.updateLocalParams();
        this.updateIngredientsFromParams();
      }

      this.dirty = true;
      this.new_component = null;
    }
    this._updateColorTile(this.params);
    if (!this.warningShown) {
      const warningStr = this.mixingBowl.getFormulaWarningStr(this.color);
      if (!!warningStr) {
        this.warningShown = true;
        const alert = await this.alertCtrl.create({
          header: 'Formula Recommendation',
          subHeader: warningStr,
          buttons: ['Ok'],
          mode: 'ios'
        });
        await alert.present();
      }
    }
  }

  // Updates the developer ratio and passes it on to all formula ingredient components
  updateDeveloperRatio() {
    this.developerRatio = this.color.developerRatio();
    if (this.developerRatio % 1 == 0)
      this.developerRatioStr = this.developerRatio.toFixed(0);
    else
      this.developerRatioStr = this.developerRatio.toFixed(2);
  }


  handleComponentChange(data) {
    this.color.setParam(data.type, data.value, data.mod);
    if (this.loading) {  // Track initial changes to the formula
      this.refColor.setParam(data.type, data.value, data.mod);
    }
    if (MixingBowlProvider.isDeveloperComponent(data.value)) {
      this.updateDeveloperRatio();
    } else {
      this.color.adjustDeveloperByRatio(this.developerRatio);
      if (this.loading) {  // Track initial changes to the formula
        this.refColor.adjustDeveloperByRatio(this.developerRatio);
      }
      this.updateLocalParams();
      this.updateIngredientsFromParams();
    }

    this._updateColorTile(this.params);
    this.dirty = true;
  }

  removeComponent(data) {
    const isBase: boolean = MixingBowlProvider.isBaseToneComponent(data.value);
    this.color.removeParam(data.type, data.value);

    this.updateLocalParams();

    // If not developer, update any developer ingredient amounts
    if (!MixingBowlProvider.isDeveloperComponent(data.value)) {
      this.color.adjustDeveloperByRatio(this.developerRatio);
      this.updateLocalParams();
      this.updateIngredientsFromParams();
    } else {
      this.updateDeveloperRatio();
    }

    // If only one component left and is developer, remove it
    if (this.color.params.length == 1 && MixingBowlProvider.isDeveloperComponent(this.color.params[0].value)) {
      this.color.removeParam(this.color.params[0].type, this.color.params[0].value);
      this.updateLocalParams();
    }

    this._updateColorTile(this.params);
    this.dirty = true;
    this.warningShown = false;  // Reset the warning pop-up
  }

  // Validate a string then call a function depending on the passed mode ('use', 'save', 'swatch')
  async validateFormula(action: string = null): Promise<boolean> {
    if (this.context == 'client' && (action == 'save' || action == 'swatch')) {  // link is disabled
      return Promise.resolve(false);
    }
    if (this.context == 'library' && action != 'save' || (this.context == 'new' && action == 'use')) {  // link is disabled
      return Promise.resolve(false);
    }
    let isOk = false;
    let result = this.mixingBowl.validateFormula(this.color, action);

    if (result.success == false) {
      if (result.mssg_key == 'mk_no_dev') {
        const alert = await this.alertCtrl.create({
          subHeader: result.mssg,
          buttons: [
            {
              text: 'No',
              handler: (data) => {
                this.doAction(action);
              }
            },
            {
              text: 'Yes',
              handler: () => {
                this.chooseComponent('Dev');
                // this.color.instantiateParam('compOrig', 'D20', this.color.tonalWeight().toFixed(3));
                // this.updateLocalParams();
              }
            }
          ],
          mode: 'ios'
        });

        await alert.present();
      } else {
        const alert = await this.alertCtrl.create({
          subHeader: result.mssg,
          buttons: ['Ok'],
          mode: 'ios'
        });

        alert.onDidDismiss().then(() => {
          if (result.mssg_key == 'mk_no_formula_title')
            this.revealNameInput();
        });

        await alert.present();
      }
    } else
      isOk = true;

    if (isOk) {

      this.doAction(action);  // If action passed, take it
    }

    return isOk;
  }

  // Delegate an action based on an action string
  async doAction(action: string) {
    if (this.dirty) {
      this.color.mode = 'basic';
      this.color.removeParamsByType('formTok');
      this.dirty = false;
    }
    switch (action) {
      case 'use':
        this.doUseColor();
        break;
      case 'save':
        if (this.color.token == null || this.color.token == '') {
          this.confirmUniqueName().then((result) => {
            if (!!result) {
              this.chooseLibrary();
            }
          });
        } else {
          let buttons: any[] = [];

          if (this.color.owned) {
            buttons.push({
              text: `Modify existing formula`,
              handler: () => {
                this.doSaveColor();
              }
            });
          }

          buttons.push({
            text: 'Create a new formula',
            handler: () => {
              if (this.color.token != null && this.color.token != '') {
                this.clicsService.findLibraryFormula(this.color.token).then((formula) => {
                  this.confirmUniqueName().then((result) => {
                    if (!!result) {
                      this.color.unlink();
                      this.chooseLibrary();
                    }
                  });
                });
              } else {
                this.color.unlink();
                this.chooseLibrary();
              }
            }
          });

          buttons.push({
            text: 'Cancel',
            role: 'cancel'
          });

          const actionSheet = await this.actionSheetCtrl.create({
            header: 'Save Color Formula',
            buttons: buttons,
            mode: 'ios'
          });

          await actionSheet.present();
        }
        break;
      case 'swatch':
        this.prepareSwatch();
        break;
    }
  }

  // Use this color in a client "app", this involves adding a FR to the client app based on this
  // NOTE: this logic has been overridden by edit queue / edit item logic w/ original logic in place below
  async doUseColor() {
    this.color.cullCompNameParams();
    this.color.assertFormulaText(true);
    this.color.eco_amount = this.color.amount;
    this.color.eco_type = 'custom';
    if (this.editCSitem) {
      this.editCSitem.itemObject.saveAppFormula(this.color, null, this.settingCtrl.getSetting('use_cob'));
      if (this.context == 'client') {
        this.editQueue.edit(this.editCSitem);
        this.modalCtrl.dismiss({modified: this._isModified(), formulaUnchanged: !!this._formulaModified()});
      } else {
        this.editQueue.editAndReturn(this.editCSitem);
      }
    } else {
      if (this.editItem) {
        if (this.context == 'client') {
          this.editQueue.edit(this.editItem);
          // this.modalCtrl.dismiss({modified: true});
          this.modalCtrl.dismiss({modified: this._isModified(), formulaUnchanged: !!this._formulaModified()});
        } else
          this.editQueue.editAndReturn(this.editItem);
      } else {
        if (this.clicsService.getUseLabApp() == true) {
          const alert = await this.alertCtrl.create({
            header: 'Use Lab App chosen',
            subHeader: 'getUseLabApp was set - should not be here - ',
            buttons: ['Ok'],
            mode: 'ios'
          });
          await alert.present();
        } else {
          let activeClient = this.clicsService.activeClient();
          if (activeClient) {
            this.modalCtrl.dismiss({modified: this._isModified(), cf: this.color, action: 'USE', formulaUnchanged: !!this._formulaModified()});
          } else {
            // No client selected, prompt for one
            this.clicsService.stashData({ca: this.color, action: 'USE'});
            const alert = await this.alertCtrl.create({
              header: 'No Client Selected',
              subHeader: 'Please choose a client before selecting colors to dispense',
              buttons: ['Ok'],
              mode: 'ios'
            });
            await alert.present();
            // this.modalCtrl.dismiss({modified: false});
            this.modalCtrl.dismiss({modified: false, formulaUnchanged: true});
            this.events.publish('navrequest', {top: 'clients', page: 'clients'});
          }
        }
      }
    }
  }

  // Checks that the current color title has not been used yet in the color library
  async confirmUniqueName(): Promise<boolean> {
    if (this.clicsService.repo.nameExistsInOwnedLibraries(this.color.title)) {
      const alert = await this.alertCtrl.create({
        header: 'Please choose a new name for this formula',
        message: `Formulas in your color ${this.modeCtrl.collectionsStr(true)} must have unique names`,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel'
          }
        ],
        mode: 'ios'
      });
      alert.onDidDismiss().then(() => {
        this.revealNameInput();
      });
      await alert.present();
      return false;
    } else
      return true;
  }

  async chooseLibrary() {
    let buttons = [];

    // Add buttons from user's libraries
    const current_user: CLiCSUser = this.clicsService.current_user;
    if (current_user) {
      const libs = this.clicsService.repo.ownedLibraries();
      for (let library of libs) {
        buttons.push({
          text: library.title,
          handler: () => {
            this.setLibraryTokenAndSave(library.token, library.title);
          }
        })
      }

      if (this.modeCtrl.isPermitted('add_collection')) {
        buttons.push({
          text: `Add new ${this.modeCtrl.collectionStr(true)}...`,
          role: 'destructive',
          handler: () => {
            actionSheet.dismiss().then(() => {
                this.addLibrary();
              }
            );
            return false;
          }
        });
      }

      buttons.push({
        text: 'Cancel',
        role: 'cancel',
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Choose Collection to Save To',
        buttons: buttons,
        mode: 'ios'
      });

      await actionSheet.present();
    }
  }

  // Set the library token and continue with save operation
  setLibraryTokenAndSave(token: string, title: string) {
    this.library_token = token;
    this.library_title = title;
    this.doSaveColor();
  }

  async addLibrary() {
    const alert = await this.alertCtrl.create({
      header: `Create New ${this.modeCtrl.collectionStr()}`,
      inputs: [
        {
          name: 'title',
          type: 'text',
          label: 'Collection Name',
          placeholder: `Enter name for new ${this.modeCtrl.collectionStr(true)}`,
          value: ''
        }
      ],
      buttons: [
        {
          text: 'Create Collection',
          handler: (data) => {
            alert.dismiss().then(() => {
              this.createLibraryAndSave(data.title);
            });
            return false;
          }
        },
        {
          text: 'Cancel',
          role: "cancel",
          handler: () => {
            alert.dismiss().then(() => {
              this.chooseLibrary();
            });
            return false;
          }
        }
      ],
      backdropDismiss: true,
      mode: 'ios'
    });
    await alert.present();
  }

  createLibraryAndSave(libraryTitle: string) {
    if (libraryTitle && libraryTitle !== "") {
      this.clicsService.apiAddUserLibrary(libraryTitle).then(async (result) => {
        if (result.success) {
          this.library_token = result.library.token;
          this.library_title = result.library.title;
          let newLibrary = new CLiCSLibrary(result.library);
          newLibrary.setScope('user');
          newLibrary.owned = true;
          this.clicsService.repo.addLibrary(newLibrary);
          this.clicsService.saveRepo();
          this.doSaveColor();
        } else {
          const toast = await this.toastCtrl.create({
            message: `${this.modeCtrl.collectionStr()} creation failed, please try again`,
            duration: 3000,
            position: 'bottom'
          });
          await toast.present().then(() => {
            this.addLibrary();
          });
        }
      });
    }
  }

  async doSaveColor() {
    if (this.color.title == null || this.color.title == "") {
      const alert = await this.alertCtrl.create({
        header: "Add a Name",
        subHeader: "Just one more thing... please add a descriptive name to your color formula so we can save it to your color collections",
        buttons: ['Ok'],
        mode: 'ios'
      });
      alert.onDidDismiss().then(() => {
        this.revealNameInput();
      });
      await alert.present();
    } else {
      let color = this.color;
      const _that = this;
      color.assertFormulaText(true);
      color.owned = true;
      this.clicsService.apiSaveColorFormula(color, this.library_token).then(async (data) => {
        if (data.success) {
          this.color.token = data.cf.token;
          const alert = await this.alertCtrl.create({
            header: "Formula Saved",
            subHeader: `Your formula was successfully saved to your color ${this.modeCtrl.collectionStr(true)}`,
            buttons: ['Ok'],
            mode: 'ios'
          });
          alert.onDidDismiss().then((overlayEvent) => {
            if (_that.context == 'library' || _that.context == 'new')
              _that.modalCtrl.dismiss({modified: true, library: _that.library_token, formula: data.cf.token, saved: true});
            else
              _that.events.publish('navrequest', {page: 'lab_color', active_formula: data.cf.token})
          });
          await alert.present();

          this.clicsService.repo.addFormula(data.cf, data.library_token);
          this.clicsService.saveRepo();
        } else {
          const alert = await this.alertCtrl.create({
            header: "Oops...",
            subHeader: "Sorry! Something went wrong when trying to save your color formula. Please review the formula name and components and try again.",
            buttons: ['Ok'],
            mode: 'ios'
          });
          await alert.present();
        }
      });
    }
  }

  // determines the amount for swatching this formula then calls doSwatchColor()
  async prepareSwatch() {
    this.loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'adding...',
      duration: 6000
    });
    await this.loader.present();
    const swatchAmount = this.mixingBowl.minAllowableWeight(this.color);
    this.doSwatchColor({amount: Math.max(swatchAmount, 20)}, this.color);
  }

  // Send a swatch request to the server
  doSwatchColor(data, swatchFormula: CLiCSColorFormula = null) {
    const formula = swatchFormula || this.color;
    if (data.amount != null) {
      this.clicsService.apiQueueSwatch(formula, data.amount).then((data) => {
        if (data.success) {
          if (!!this.loader) {
            this.loader.dismiss();
            this.loader = null;
          }
          this.alertCtrl.create({
            header: 'Ready to Dispense',
            subHeader: 'Your swatch is ready to dispense from any available machine',
            buttons: ['Ok'],
            mode: 'ios'
          }).then(alert => alert.present());
        } else {
          if (!!this.loader) {
            this.loader.dismiss();
            this.loader = null;
          }
          this.alertCtrl.create({
            header: 'Ummm...',
            subHeader: `Something happened and your swatch did not qet queued properly. Please try again. Message: ${data.message_key}`,
            buttons: ['Ok'],
            mode: 'ios'
          }).then(alert => alert.present());
        }
      }, (result) => {
        if (!!this.loader) {
          this.loader.dismiss();
          this.loader = null;
        }
        this.alertCtrl.create({
          header: 'Oops...',
          subHeader: 'Sorry! Something went wrong and your swatch did not get queued properly. Please try again.',
          buttons: ['Ok'],
          mode: 'ios'
        }).then(alert => alert.present());
      });
    }
    this.promptForSwatch = false;
    if (this.context == 'history')
      this.closeModal();
  }

  cancelSwatch() {
    this.promptForSwatch = false;
  }

  // Clear changes and load a blank CF
  // TODO: reset to reference color (tried below but didn't work well)
  resetColor() {
    if (this.context == 'history') {  // Link is disabled
      return;
    }
    this.developerRatio = 1.0;
    this.color = new CLiCSColorFormula();
    // this.color = new CLiCSColorFormula(this.refColor);
    this.updateLocalParams();
    this.showNameInput = false;
    this.setPageTitle();
    this._updateColorTile(this.params);
  }

  // The array of params used locally is separate from the list of params in the color formula object. compOrig params
  // are used in preference to compName params, but will fall back if not present.
  updateLocalParams() {
    let paramType = 'compName';
    if (!!this.color && this.color.usesOriginal())
      paramType = 'compOrig';

    this.params.length = 0;
    if (this.color) {
      this.params = this.color.params.filter((el) => {
        return (el.type == paramType);
      });

      // Sorting is useful but results in amount mismatch
      this.params.sort((a, b) => {
        if (MixingBowlProvider.isBaseToneComponent(a.value))
          return -1;
        else {
          if (MixingBowlProvider.isBaseToneComponent(b.value))
            return 1;
        }
        if (MixingBowlProvider.isDeveloperComponent(a.value))
          return 1;
        else {
          if (MixingBowlProvider.isDeveloperComponent(b.value))
            return -1;
        }
      });
    }
  }

  // Updates the values in the
  updateIngredientsFromParams() {
    if (this.color) {
      this.ingredients.forEach((ingredient, index) => {
        for (let param of this.params) {
          if (param.value == ingredient.ingredientName) {
            ingredient.setQuantity(parseFloat(param.mod));
            break;
          }
        }
      });
    } else {
      this.params = [];
    }
  }

  setFormulaTitle(event) {
    this.title = event.detail.value.trim();
    this.color.title = this.title;
  }

  saveFormulaTitle() {
    this.showNameInput = false;
    this.setPageTitle();
    this.keyboard.hide();
  }

  revealNameInput() {
    this.showNameInput = true;
    setTimeout(() => {
      this.titleFormInput.setFocus();
    }, 150);
  }

  setPageTitle() {
    if (this.color) {
      if (this.color.token != null && this.color.token != "") {
        if (this.color.title != null && this.color.title != "")
          this.pageTitle = `Editing: ${this.color.title}`;
        else
          this.pageTitle = "Editing Color";
      } else {
        if (this.color.title == "")
          this.pageTitle = "Design a Color";
        else
          this.pageTitle = this.color.title;
      }

      // Set the little link text
      if (this.color.title == '')
        this.nameLabel = 'name it';
      else
        this.nameLabel = 'change';

    } else {
      this.pageTitle = "Create Color";
    }
  }

  async confirmCancel() {
    if (this.context != 'client' && this.context != 'library' && this.context != 'new') { // Link is disabled
      return;
    }
    if (this.dirty && this.params.length > 1) {
      let mssg: string = 'Discard all changes and close this page?';

      if (this.context == 'library')
        mssg = 'Discard all changes and return to the color collection?';
      if (this.context == 'client')
        mssg = 'Discard all changes and return to the client application?';

      const alert = await this.alertCtrl.create({
        header: 'Confirm Cancel',
        message: mssg,
        buttons: [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.closeModal();
            }
          }
        ],
        mode: 'ios'
      });
      await alert.present();
    } else
      this.closeModal();
  }

  // Close modal view, return unmodified result
  closeModal() {
    this.dirty = false;
    // this.modalCtrl.dismiss({modified: false});
    this.modalCtrl.dismiss({modified: false, formulaUnchanged: true});
  }

  _updateColorTile(params) {
    let newColor = '#ffffff';
    let realParams: any[] = [];
    let paramType = 'compName';
    const index = params.findIndex((el) => {
      return (el.type == 'compOrig');
    });
    if (index >= 0)
      paramType = 'compOrig';

    for (let param of params) {
      if (param.value.match(/\d+(.\d+)?N/) != null || param.value.match(/D\d+(.\d+)?/) != null) {
        let real = this.mixingBowl.getRealFromFractional(param.value, parseInt(param.mod));
        for (let el of real) {
          realParams.push({type: paramType, value: el.product.name, mod: el.weight.toString()});
        }
      } else {
        realParams.push(param);
      }
    }

    // !!! The module RETURNS an error rather than throwing it
    try {
      if (realParams.length > 0) {
        newColor = this.colorFormulaToRGB.transform(realParams);
        this.color.rgb = newColor;
      }

      const rgb = this.mixingBowl.hexToRgb(newColor);
      if (!!rgb && 'r' in rgb && 'g' in rgb && 'b' in rgb) {
        this.tileLevel = this.mixingBowl.levelFromRGB(rgb.r, rgb.g, rgb.b);  // to change overlay
      } else {
        this.tileLevel = 6;
      }

      this.tileColor = newColor;
    } catch (err) {
      console.error(err);
    }
  }

  // Developer amount is chosen by slider, this adds developer of the strength chosen
  addDeveloper(data) {
    this.promptForDeveloper = false;
    this.handleProductDirectSelect('D' + data.amount);
  }

  populateZeroAmount(data: any) {
    this.ingredients.forEach((ingredient, index) => {
      if (ingredient.ingredientQuantity == 0.0)
        ingredient.setQuantity(1);
    });
  }

  // Was the formula changed, aside from the title?
  _formulaModified(): boolean {
    let modified = false;

    // Did any params change?
    for (let param of this.color.params) {
      const index = this.refColor.params.findIndex((el) => {
        return (el.type == param.type && el.value == param.value && el.mod == param.mod);
      });
      if (index < 0) {
        modified = true;
        break;
      }
    }

    const refParams = this.refColor.params.filter(el => el.type == 'compOrig');
    const newParams = this.color.params.filter(el => el.type == 'compOrig');

    // Did other formula information change?
    if (!modified) {
      modified = (
        refParams.length > newParams.length ||
        this.refColor.mode != this.color.mode ||
        this.refColor.coverage != this.color.coverage
      )
    }

    return modified;
  }

  // Was anything changed, including the title
  _isModified(): boolean {
    let modified = this._formulaModified();
    if (!modified) {
      modified = this.refColor.title != this.color.title;
    }
    return modified;
  }

}
