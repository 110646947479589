<ion-header class="naked">
  <ion-title>Scan Canisters</ion-title>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="!isFinished && !!isFullScan" >
    <p>Scan every canister at the salon that is not in a dispenser machine for a complete inventory update.</p>
    <p><strong>Each <em>individual</em> canister must be scanned. Scanning a single canister multiple times will not count as multiple canisters.</strong>
      If you're not sure whether you've already scanned a particular canister it's OK to scan it again to be sure.</p>
  </div>
  <div *ngIf="!isFinished && !isFullScan && !incomingScan">
    <p>Scan individual canisters that are new to the salon or have not yet been scanned. If you're not sure if a
      canister is included in the inventory count go ahead and scan it anyway.</p>
    <p><strong>Individual canisters must be scanned. Scanning a single canister multiple times will not count as multiple canisters.</strong></p>
  </div>
  <div *ngIf="!isFinished && !isFullScan && !!incomingScan">
    <p>Please scan all of the canisters in this product shipment. <strong>Note: each individual canister must be scanned.
      Scanning a single canister multiple times will not count as multiple canisters.</strong> Set aside any unusable, damaged canisters, these will be scanned at the end.</p>
  </div>
  <ion-button class="clics active" *ngIf="!isFinished" (click)="scanNext()">{{scanNextText}}</ion-button>&nbsp;
  <ion-button class="clics" *ngIf="isFullScan && !isFinished" (click)="promptFinishScan()">Finish Up</ion-button>&nbsp;
  <ion-button class="clics" *ngIf="!isFullScan && !isFinished" (click)="finishPartialScan()">Finish Up</ion-button>
  <p [innerHTML]="report"></p>
  <p *ngIf="!!isFinished && !!incomingScan"><strong>If any canisters are damaged and unusable please scan them now.</strong><br><ion-button (click)="scanDamaged()">Scan Damaged Canisters</ion-button><br><br></p>
  <div *ngIf="!report || report == ''"><br></div>
  <ion-button class="clics" *ngIf="isFullScan && !isFinished" (click)="cancelScan()">Cancel Inventory Scan</ion-button>&nbsp;
  <ion-button class="clics" *ngIf="isFinished" (click)="closeWindow()">Done</ion-button>
  <br>
  <img *ngIf="!isFinished" src="assets/images/barcode_scan.jpg" class="scan_image" alt="canister barcode being scanned illustration" />
</ion-content>
<ion-footer>

</ion-footer>
