<ion-content class="ion-padding">
  <section>
    <h2>Color Filter</h2>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-toggle [checked]="filterSpec.library.libCLiCS"></ion-toggle>
            <ion-label>CLICS</ion-label>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-toggle [checked]="filterSpec.library.libSalon"></ion-toggle>
            <ion-label>Salon</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-toggle [checked]="filterSpec.library.libDesigned"></ion-toggle>
            <ion-label>My Colors</ion-label>
          </ion-item>
        </ion-col>
        <ion-col>
<!--                    <ion-item>-->
                <!--                        <ion-toggle [checked]="filterSpec.library.libSaved"></ion-toggle>-->
                <!--                        <ion-label>Lookbook</ion-label>-->
<!--                    </ion-item>-->
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
  <section id="level_range_selection">
    <h2>Base Tone Range</h2>
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="range-select-bar">
            <div class="label">{{filterSpec.level.lower}}</div>
            <div class="label right">{{filterSpec.level.upper}}</div>
            <ion-item>
              <ion-range [value]="filterSpec.level" [dualKnobs]=true [min]=1 [max]=12 [pin]=true [snaps]=true >
                <ion-icon size="small" slot="start" name="sunny"></ion-icon>
                <ion-icon slot="end" name="sunny"></ion-icon>
              </ion-range>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
  <section>
    <ion-button class="clics" (click)="doClearFilter()">Clear Filter</ion-button>
    <ion-button (click)="doSetFilter()">Set Filter</ion-button>
  </section>
</ion-content>
