<ion-header>
  <ion-toolbar>
    <ion-title>Client Notes: <span *ngIf="client">{{clientName}}</span></ion-title>
    <ion-buttons slot="primary">
      <clics-icon text="CLOSE" class="clickable" (click)="closeModal()"></clics-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class='ion-padding' *ngIf="client">
  <div id="client-notes" *ngIf="client">
    <div id="add-note" (click)="showNoteEditor()">
      <ion-icon name="add"></ion-icon>
    </div>
    <h4 *ngIf="clientNotes.length == 0">This is the place to record client notes. To add a note press the '+' icon.</h4>
    <div class="client-note" *ngFor="let note of this.client.user_notes" [attr.data-token]="note.token">
      <h4>
        {{formattedDate(note.noteDate)}}
        <span>
          <ion-icon name="create" (click)="editNote(note.token)"></ion-icon>
        </span>
      </h4>
      <p>{{note.noteText}}</p>
      <ion-button class="clics" size="small" *ngIf="!!note.css" (click)="showApplicationDetails(note.css)">view app</ion-button>
    </div>
  </div>
</ion-content>
