<!--<ion-header class="ion-no-border">-->
<!--  <ion-title>Create Color</ion-title>-->
<!--</ion-header>-->
<ion-content class="ion-padding">
  <div id="title-view">
    <h2 *ngIf="showNameInput == false" (click)="revealNameInput()">{{pageTitle}}</h2>
    <ion-button id="name_it_button" class="clics small" (click)="revealNameInput()" *ngIf="showNameInput == false">{{nameLabel}}</ion-button>
  </div>
  <div id="name_entry" *ngIf="showNameInput == true">
    <ion-item lines="none">
      <ion-label position="stacked">Name your formula</ion-label>
      <ion-input #titleInput type="text" [value]="title" (ionChange)="setFormulaTitle($event)" (keyup.enter)="saveFormulaTitle()" class="clics"></ion-input>
    </ion-item>
  </div>
  <p class="diminished" *ngIf="color && color.library">{{modeCtrl.collectionStr()}}: {{color.library}}</p>
  <div class="color-preview">
    <color-tile class="full-width" [level]="tileLevel" [backgroundColor]="tileColor"></color-tile>
    <p class="disclaimer">{{mixingBowl.screenDisclaimer}}</p>
  </div>
  <div class="tone-selection">
    <ion-grid>
      <ion-row class="component-add-buttons">
        <ion-col size="3">
          <ion-button class="clics large" size="large" (click)="chooseComponent('Base')">
            <ion-icon name="add"></ion-icon>
            Base
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button class="clics" size="large" (click)="chooseComponent('Cool')">
            <ion-icon name="add"></ion-icon>
            Cool
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button class="clics" size="large" (click)="chooseComponent('Warm')">
            <ion-icon name="add"></ion-icon>
            Warm
          </ion-button>
        </ion-col>
        <ion-col size="3">
          <ion-button size="large" (click)="chooseComponent('Dev')" class="clics wider">
            <ion-icon name="add"></ion-icon>
            Dev
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="component-selection">
    <div class="components">
      <formula-ingredient *ngFor="let param of params" name="{{param.value}}" qty="{{param.mod}}"
                          developerRatio="{{developerRatioStr}}"
                          (valueChange)="handleComponentChange($event)"
                          (delete)="removeComponent($event)"
                          (focusout)="populateZeroAmount($event)"></formula-ingredient>
    </div>
  </div>
</ion-content>
<ion-footer>
    <div class="bottom_action">
    <ion-grid>
      <ion-row>
        <ion-col><clics-icon [theme]="theme" class="disabled" [text]="'Design'"></clics-icon></ion-col>
        <ion-col class="clickable">
         <clics-icon [theme]="theme" [class.disabled]="context == 'library' || context == 'new'" (click)="validateFormula('use')" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col><clics-icon [theme]="theme" class="disabled" [text]="'Add To...'"></clics-icon></ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="clickable">
         <clics-icon [theme]="'dark'" [class.disabled]="context == 'history'" (click)="resetColor()" [text]="'Reset'"></clics-icon>
        </ion-col>
        <ion-col class="clickable">
         <clics-icon [theme]="'dark'" [class.disabled]="context == 'client'" (click)="validateFormula('swatch')" [text]="'Swatch'"></clics-icon>
        </ion-col>
        <ion-col class="clickable">
          <clics-icon [theme]="'dark'" [class.disabled]="context == 'client'" (click)="validateFormula('save')" [text]="'Save'"></clics-icon>
        </ion-col>
        <ion-col class="clickable">
          <clics-icon [theme]="'dark'" [class.disabled]="context != 'client' && context != 'library' && context != 'new'"
                      (click)="confirmCancel()" [text]="'Cancel'"></clics-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>

<dispense-confirm *ngIf="promptForSwatch" (confirmation)="doSwatchColor($event)"
                  (cancel)="cancelSwatch()"></dispense-confirm>

<dispense-confirm
  *ngIf="promptForDeveloper"
  (confirmation)="addDeveloper($event)"
  [min]="1"
  [max]="40"
  [default]="20"
  [unit]="''"
  [title]="'Developer Strength'"
  [confirmText]="'Add'"
  [showMinWeightText]="false"
  (cancel)="promptForDeveloper = false"></dispense-confirm>
