<ion-header class="naked">
  <ion-toolbar>
    <ion-title>Page Help</ion-title>
    <ion-buttons slot="primary">
      <clics-icon text="CLOSE" class="clickable" (click)="closeModal()"></clics-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="help-content" #helpcontentdiv [innerHtml]="helpContent"></div>
</ion-content>
<ion-footer class="ion-padding">
<!--  <ion-list lines="none">-->
    <div class="main_icons">
<!--      <ion-item>-->
        <clics-icon [theme]="theme" (click)="showWelcomeSlides()" [text]="'Slides'"></clics-icon>
<!--      </ion-item>-->
<!--      <ion-item>-->
        <clics-icon [theme]="theme" (click)="openTrainingPage()" [text]="'Education'"></clics-icon>
<!--      </ion-item>-->
<!--      <ion-item>-->
        <clics-icon [theme]="theme" (click)="openAccountPage()" [text]="'My Account'"></clics-icon>
<!--      </ion-item>-->
    </div>
    <div class="right_icons">
<!--      <ion-item class="right">-->
        <clics-icon [theme]="theme" (click)="showFeedbackForm()" [text]="'Support'"></clics-icon>
<!--      </ion-item>-->
    </div>
<!--  </ion-list>-->
</ion-footer>
