<ion-header class="naked">
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button class="icon-only" (click)="closeModal()"><ion-icon ion-icon name="close-circle-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list>
    <ion-item lines="none">
      <ion-label class="clics" position="stacked">Enter a note</ion-label>
      <ion-textarea
        lines="none"
        border="none"
        rows="6"
        auto-grow="true"
        mode="md"
        placekeeper="Enter your note here"
        (ionChange)="setNoteText($event)"
        [value]="noteText">
      </ion-textarea>
    </ion-item>
    <br>
    <ion-button class="clics" (click)="saveNoteForSelectedClient()">Save Note</ion-button>
    <ion-button class="clics delete-button" (click)="deleteNote()" *ngIf="this.editable">Delete Note</ion-button>
  </ion-list>
</ion-content>
