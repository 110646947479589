<div class="dispense-product">
  <div class="header">
    <div class="product">Dev. Strength</div>
    <div class="product-amount">{{strength}}</div>
  </div>
  <div class="bottom">
    <div class="slider-container">
      <amount-slider [noGradient]=true [showTitle]=false [min]=1 [max]=40 [restrictMax]=true [amount]=strength [unit]=unit
                     (onChange)="updateStrength($event)"></amount-slider>
    </div>
  </div>
</div>
<div class="dispense-product">
  <div class="header">
    <div class="product">Dev. Amount</div>
    <div class="product-amount">{{valStr}} <span>{{ozValStr}}</span></div>
  </div>
  <div class="bottom">
    <div class="slider-container">
      <amount-slider [noGradient]=true [showTitle]=false [min]=0 [max]=300 [restrictMax]=true
                     (onChange)="updateVal($event)"></amount-slider>
    </div>
    <div class="action" (click)="doRequestDispense()">
      <div class="action-icon">&nbsp;</div>
    </div>
  </div>
</div>
