<ion-header class="naked">
  <ion-title>Scan Tracking Barcode</ion-title>
</ion-header>
<ion-content class="ion-padding">
  <div class="inventory-incoming-tracking-container">
    <div class="tracking_intro">
      <p>Please scan the tracking number barcode on the box your product was shipped in. Click on "Scan Tracking Number"
        below then you can scan the barcode with the camera on this device. The tracking number barcode is
        usually the largest barcode on the box (see illustration below).</p>
      <img src="assets/images/tracking_label.jpg" alt="tracking number barcode illustration"/>
      <ion-button class="clics" (click)="scanTrackingNumber()" *ngIf="!trackingResult">Scan Tracking Number</ion-button>
      <ion-button class="clics" (click)="cancel()" *ngIf="!trackingResult">Cancel</ion-button>
      <p *ngIf="!!this.report" class="error">{{this.report}}</p>
    </div>
    <div class="tracking_result" *ngIf="!!trackingResult">
      <h3>{{trackingResult}}</h3>
      <div *ngIf="showRetryButtons">
        <ion-button class="clics" (click)="bypassScanning()" *ngIf="!!giveUp">Continue without tracking</ion-button>
        <ion-button class="clics" (click)="rescan()">Try Again</ion-button>
      </div>
      <ion-button class="clics" (click)="cancel()">Cancel</ion-button>
    </div>
  </div>
</ion-content>
<ion-footer>
</ion-footer>
