<div class="app-create-formula-container">
  <div class="app-title">
    <div class="choices" (click)="doFormulaChoices(formula.token, formula.request_ident)">
      <div class="location">
        {{ formula.location }}
      </div>
      <div class="name">
        {{ formula.titleString() }}
      </div>
    </div>
    <div class="image">
      <div class="color_swatch" [style.backgroundColor]=formula.rgb (click)="doColorDetail(formula.token, formula.request_ident)"></div>
    </div>
    <div class="amount-string" *ngIf="!hideAmount && formula.amount && formula.amount > 0.0">
      {{ formula.amount }}{{unit}}
    </div>
    <div class="coverage">
      {{ formula.info }}
    </div>
  </div>
</div>
