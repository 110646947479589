<ion-header class="naked">
  <ion-toolbar>
    <ion-title>Color Detail</ion-title>
    <ion-buttons slot="primary">
      <clics-icon text="CLOSE" class="clickable" (click)="closeModal()"></clics-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="component-selection">
    <div id="name_entry" *ngIf="showNameInput == true">
      <ion-item lines="none">
        <ion-label position="stacked">Name your formula</ion-label>
        <ion-input class="name_input" #titleInput type="text" [value]="formula.title" (ionChange)="setFormulaTitle($event)" (keyup.enter)="saveFormulaTitle()" class="clics"></ion-input>
      </ion-item>
      <br>
    </div>
    <h2 class="tight" *ngIf="formula && formula.title && showNameInput == false">
      {{title}}
      <clics-icon *ngIf="!!adjustedFormula && !!adjustedFormula.pedigree" [theme]="'dark'" class="pedigree" [iconOnly]="true" [special]="true" [text]="adjustedFormula.pedigreeText()"></clics-icon>
      <clics-icon *ngIf="!!adjustedFormula && !!adjustedFormula.pedigree && !!adjustedFormula.pedigreeSoftChange()" [theme]="'dark'" class="soft-change" [iconOnly]="true" [special]="true" [text]="'SOFT'"></clics-icon>
    </h2>
    <p id="library_name" class="diminished" *ngIf="formula && formula.library">Library: <span
      [innerHTML]="formula.library"></span></p>
    <color-pill [formula]="formula"></color-pill>
    <p class="disclaimer">{{mixingBowl.screenDisclaimer}}</p>
    <p class="color-formula" *ngIf="!!this.adjustedFormula && (this.formulaOwnedByMe || permissions.view_all_formulas)">
      <span>Formula:</span> {{adjustedFormula.formula_text}}
    </p>
    <p class="color-formula" *ngIf="!this.adjustedFormula && (this.formulaOwnedByMe || permissions.view_all_formulas)">
      <span>Formula:</span> {{formula.formula_text}}
    </p>
    <div class="fr_details" *ngIf="!!formula && formula.isFormulaRequest()">
      <div class="fr_coverage">{{formula.info}}</div>
      <div class="fr_dev" *ngIf="formula.hasDeveloper() && !formula.isDeveloperOnly()">{{'D' + formula.developerStrength()}}</div>
      <div class="fr_dev" *ngIf="formula.hasDeveloper() && !formula.isDeveloperOnly()">{{formula.developerRatio() + ':1'}}</div>
    </div>
    <div *ngIf="!!permissions && permissions.customize && settings.cust_sho && this.source != 'history' && this.context != 'client'" id="customize-button" class="clickable">
      <ion-button class="clics" size="small" (click)="doCustomize()">customize</ion-button>
    </div>
  </div>
  <div class="member_libraries" *ngIf="memberLibraries.length > 0">
    <h4 *ngIf="memberLibraries.length == 1">Included in {{modeCtrl.collectionStr(true)}}:</h4>
    <h4 *ngIf="memberLibraries.length > 1">Included in {{modeCtrl.collectionsStr(true)}}:</h4>
    <table>
      <tr *ngFor="let lib of memberLibraries">
        <td class="lib_title" col-3 [innerHTML]="lib.title"></td>
        <td col-2 class="clickable" (click)="removeFromLibrary(lib.token)" *ngIf="!!lib.owned && memberLibraries.length > 1">remove
        </td>
      </tr>
    </table>
  </div>
</ion-content>
<ion-footer>
  <div class="bottom_action single">
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="!!permissions && !!permissions.show_swatch" class="clickable">
         <clics-icon [theme]="'dark'" [class.disabled]="preventFastDispense === true" (click)="prepareSwatch()" [text]="'Swatch'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="source == 'history'">
         <clics-icon [theme]="'dark'" (click)="useFormulaFromHistory()" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!permissions && !permissions.show_swatch">&nbsp;</ion-col> <!--Intentionally shifted in order to center USE link-->
        <ion-col *ngIf="source != 'history'">
         <clics-icon [theme]="'dark'" (click)="useFormulaFromLibrary()" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="formulaOwnedByMe && ! hideCustomizeLink">
         <clics-icon [theme]="'dark'" [class.disabled]="source == 'clapp'" (click)="promptForRemove()" [text]="'Delete'"></clics-icon>
        </ion-col>
        <ion-col class="clickable" *ngIf="!formulaOwnedByMe || hideCustomizeLink">
         <clics-icon [theme]="'dark'" [class.disabled]="saveIsDisabled()" (click)="doSaveColor()" [text]="'Save'"></clics-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
<dispense-confirm *ngIf="promptForSwatch" (confirmation)="doSwatchColor($event)" [default]="defaultWeight"
                  [min]="minFormulaWeight" [max]="maxFormulaWeight"
                  (cancel)="promptForSwatch = false;"></dispense-confirm>
