<ion-header *ngIf="requestedView != 'modal'">
  <navigation-bar></navigation-bar>
  <navigation-sidebar current_root='lab'></navigation-sidebar>
</ion-header>
<ion-header class="naked" *ngIf="requestedView == 'modal'">
  <ion-title *ngIf="modeCtrl.notSimpleMode()" mode="md">{{collection_str || 'Add Colors'}}</ion-title>
</ion-header>
<ion-content class="ion-padding">
  <ion-searchbar
    #searchBar
    showCancelButton="always"
    showClearButton="always"
    cancelButtonText=""
    searchIcon="arrow-back-outline"
    cancelButtonIcon="arrow-back-outline"
    inputmode="search"
    mode="md"
    (ionInput)="applySearch($event)"
    (ionCancel)="cancelSearch()"
    *ngIf="showSearch">
  </ion-searchbar>
  <div>
    <div class="page-header" *ngIf="showDisplay">
      <div class="title" *ngIf="target != 'conversions'">
        <ion-item lines="none">
          <div class="value" (click)="chooseLibrary()" id="library_button">{{libraryDisplay}}</div>
          <ion-icon name="chevron-down-circle-outline" (click)="chooseLibrary()" *ngIf="selectedLibraryTitle==''"></ion-icon>
          <ion-icon name="close-circle-outline" (click)="clearLibrarySelection()" *ngIf="selectedLibraryTitle!=''"></ion-icon>
        </ion-item>
      </div>
      <div class="title" *ngIf="target == 'conversions'">
        <ion-item lines="none" *ngIf="!libraryIdent">
          <ion-label class="value conv" (click)="chooseConversionManufacturer()" [innerHTML]="manufacturerName"></ion-label>
          <ion-icon name="chevron-down-circle-outline" (click)="chooseConversionManufacturer()"></ion-icon>
        </ion-item>
        <ion-item lines="none" *ngIf="!!manufacturerId">
          <ion-label class="value conv" (click)="chooseConversionLibrary()" [innerHTML]="libraryDisplay"></ion-label>
          <ion-icon name="chevron-down-circle-outline" (click)="chooseConversionLibrary()" *ngIf="!libraryIdent"></ion-icon>
          <ion-icon name="close-circle-outline" (click)="clearBrandSelection()" *ngIf="!!libraryIdent"></ion-icon>
        </ion-item>
      </div>
      <div class="menu">
        <record-menu class="search filter"
                     [showFilter]="permissions.show_lab_filter"
                     [class.setting]="showSettings()"
                     [class.filter-active]="activeLibrary.filterSpec && activeLibrary.filterSpec.cleared == false"
                     (openSearch)="toggleShowSearch()"
                     (openFilter)="showFilterPage()"
                     (openSettings)="promptForLibrarySettings()">
        </record-menu>
      </div>
    </div>
    <div class="color_library"
         *ngIf="showDisplay && activeLibrary.filterSpec != null && activeLibrary.filterSpec.cleared == false && activeLibrary.filteredFormulas.length == 0">
      <br><br>
      <h2>No color formulas match the selected filters</h2>
    </div>
    <div class="color_library" id="color_library_div" *ngIf="showDisplay && activeLibrary.filteredFormulas.length > 0">
      <div class="library_count">{{this.activeLibrary.selectedFormulas.length}}
        / {{activeLibrary.filteredFormulas.length}}</div>
      <div *ngIf="compareMode == false" class="color-grid">
        <color-grid-image
          *ngFor="let formula of this.activeLibrary.filteredFormulas"
          [formula]="formula" [content]="content"
          (selectFormula)="handleColorClick(formula.token, formula)"
          (inspectFormula)="this.showColorDetail($event)"></color-grid-image>
      </div>
      <div *ngIf="compareMode == true" class="color-grid">
        <color-bar-image
          *ngFor="let formula of this.repo.selectedFormulas"
          [formula]="formula"
          (selectFormula)="handleColorClick($event, formula)"></color-bar-image>
      </div>
    </div>
    <div class="color_library"
         *ngIf="showDisplay &&
         (activeLibrary.filterSpec == null || activeLibrary.filterSpec.cleared == true) &&
         activeLibrary.filteredFormulas.length == 0 &&
         target != 'conversions'">
      <br><br>
      <h3>There are no color formulas in this {{modeCtrl.collectionStr(true)}}</h3>
      <div class="reload_button">
        <ion-button class="clics" (click)="reloadColorLibrary()">Reload Color {{modeCtrl.collectionsStr()}}</ion-button>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="color_actions bottom_action no_border" id="color_action_div_top">
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="!!this.repo && !permissions.enable_grouped_formulas || (target == 'colors' && !this.repo.multipleSelection())">
          <clics-icon [theme]="theme" [class.disabled]="target != 'colors' || (context == 'client' || !permissions.create_color)"
                      (click)="createNewLibraryColor()" [text]="'Design'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!this.repo && permissions.enable_grouped_formulas && (target != 'colors' || this.repo.multipleSelection()) && requestedView == 'modal'">
          <clics-icon [theme]="theme" [class.disabled]="!this.repo.multipleSelection()"
                      (click)="addColorsToTargetApp(true)" [text]="'Combine'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!this.repo && permissions.enable_grouped_formulas && (target != 'colors' || this.repo.multipleSelection()) && requestedView != 'modal'">
          <clics-icon [theme]="theme" [class.disabled]="!this.repo.multipleSelection()"
                      (click)="confirmUseColor(true)" [text]="'Combine'"></clics-icon>
        </ion-col>


        <ion-col *ngIf="anySelected() && requestedView == 'modal'">
          <clics-icon [theme]="theme" (click)="addColorsToTargetApp()" [text]="'Use'"></clics-icon></ion-col>
        <ion-col *ngIf="anySelected() && requestedView != 'modal'">
          <clics-icon [theme]="theme" (click)="confirmUseColor()" [text]="'Use'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!anySelected()">
          <clics-icon [theme]="theme" class="disabled" [text]="'Use'"></clics-icon>
        </ion-col>

        <ion-col *ngIf="!!permissions.show_add_to && showAddTo() && (anySelected() && target=='colors')">
          <clics-icon [theme]="theme" (click)="checkElegibleFormulas()" [text]="'Add To...'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!permissions.show_add_to && showAddTo() && (!anySelected() || target!='colors')">
          <clics-icon [theme]="theme" class="disabled" [text]="'Add To...'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!permissions.show_add_to && showRemove() && this.activeLibrary.selectedFormulas.length > 0">
          <clics-icon [theme]="theme" (click)="promptForRemove()" [text]="'Remove...'"></clics-icon></ion-col>
        <ion-col *ngIf="!!permissions.show_add_to && showRemove() && this.activeLibrary.selectedFormulas.length <= 0">
          <clics-icon [theme]="theme" class="disabled" [text]="'Remove...'"></clics-icon>
        </ion-col>

        <ion-col *ngIf="!permissions.show_add_to">&nbsp;</ion-col>
      </ion-row>

      <ion-row>
        <ion-col *ngIf="!!this.repo && !!this.repo.hasSelection()">
          <clics-icon [theme]="'dark'" (click)="resetColors()" [text]="'Reset'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!this.repo && !this.repo.hasSelection()">
          <clics-icon [theme]="'dark'" class="disabled" [text]="'Reset'"></clics-icon>
        </ion-col>

        <ion-col *ngIf="!!permissions.show_swatch && (anySelected() && requestedView != 'modal')">
          <clics-icon [theme]="'dark'" (click)="doSwatchColors()" [text]="'Swatch'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!permissions.show_swatch && (!anySelected() || requestedView == 'modal')">
          <clics-icon [text]="'Swatch'" class="disabled" [theme]="'dark'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!permissions.show_swatch">
          &nbsp;
        </ion-col>
        <ion-col *ngIf="!!this.repo && compareMode == false && !!this.repo.multipleSelection()">
          <clics-icon [theme]="'dark'" (click)="compareMode = true;" [text]="'Compare'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="!!this.repo && compareMode == false && !this.repo.multipleSelection()">
          <clics-icon [theme]="'dark'" class="disabled" [text]="'Compare'"></clics-icon>
        </ion-col>
        <ion-col *ngIf="compareMode == true">
          <clics-icon [theme]="'dark'" (click)="compareMode = false;" [text]="'Show All'"></clics-icon>
        </ion-col>

        <ion-col><clics-icon [theme]="'dark'" [class.disabled]="requestedView != 'modal'" (click)="closeModal()" [text]="'Cancel'"></clics-icon></ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <page-footer *ngIf="requestedView != 'modal'">&nbsp;</page-footer>
</ion-footer>
<dispense-confirm *ngIf="promptForSwatch"
                  (confirmation)="doSwatchColors($event)"
                  [min]="minFormulaWeight"
                  [max]="maxFormulaWeight"
                  [default]="defaultWeight"
                  [minWeightText]="'min for selected formulas'"
                  (cancel)="promptForSwatch = false;"></dispense-confirm>
