<div class="modal-backdrop" #developer_confirm_modal>
  <div class="modal-content">
    <div class="dispense-product">
      <div class="header">
        <div class="product">Dev. Strength</div>
        <div class="product-amount">{{strength}}</div>
      </div>
      <div class="bottom">
        <div class="slider-container">
          <amount-slider [noGradient]=true [showTitle]=false [min]="minStrength" [max]=40 [restrictMax]=true
                         [amount]=strength
                         (onChange)="updateStrength($event)"></amount-slider>
        </div>
      </div>
    </div>
    <div class="dispense-product">
      <div class="header">
        <div class="product">Dev. Amount</div>
        <div class="product-amount">{{valStr}} <span>{{ozValStr}}</span></div>
      </div>
      <div class="bottom">
        <div class="slider-container">
          <amount-slider #amountSelect [noGradient]=true [showTitle]=false [min]=min [max]=max [restrictMax]=true
                         (onChange)="updateVal($event)"></amount-slider>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <ion-button class="clics" (click)="doCancel()">Cancel</ion-button> &nbsp;
      <ion-button class="clics" (click)="doRequestDispense()">{{confirmText}}</ion-button>
    </div>
  </div>
</div>
