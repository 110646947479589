<div class="modal-backdrop" #dispense_confirm_modal>
  <div class="modal-content" id="dispense_confirm_div">
    <h2>{{title}}</h2>
    <h3>{{valStr}}</h3>
    <div class="amount-slider-container">
      <amount-slider (onChange)="updateValue($event)" [amount]=value [showTitle]=false [min]=min [max]=max [restrictMax]=true [noGradient]=true [unit]=unit></amount-slider>
      <p *ngIf="atMinWeight()">{{minWeightText}}: {{min}}{{unit}}</p>
    </div>

    <div class="option-switch" *ngIf="!!option">
      <ion-list no-lines>
        <ion-item>
          <ion-label>{{option}}</ion-label>
          <ion-toggle [(ngModel)]="optionState"></ion-toggle>
        </ion-item>
      </ion-list>
    </div>
    <div class="option-modifier" *ngIf="!!option && !!optionState">
      <h4>{{optionValStr}}</h4>
      <ion-list no-lines>
        <ion-item>
          <ion-range min="{{optionMin}}" max="{{optionMax}}" [(ngModel)]="optionModValue"
                     (ionChange)="updateModValue()" class="dispense_confirm_slider">
            <ion-label slot="start">D{{optionMin}}</ion-label>
            <ion-label slot="end">D{{optionMax}}</ion-label>
          </ion-range>
        </ion-item>
      </ion-list>
    </div>
    <div class="confirm-buttons">
      <ion-button class="clics" (click)="doCancel()">cancel</ion-button>
      <ion-button class="clics" (click)="doDispense()">{{confirmText}}</ion-button>
    </div>
  </div>
</div>
